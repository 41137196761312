/* eslint-disable */
import { SanitizedGQLError } from "@/types/errors";
import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from "react-query";
import { fetcher } from "./fetcher";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
};

export type AboutPage = {
  __typename?: "AboutPage";
  body: Scalars["String"];
  cacheTime: Scalars["Float"];
  heading: Scalars["String"];
};

export type Account = {
  __typename?: "Account";
  _id: Scalars["String"];
  accountName: Scalars["String"];
  accountType: AccountType;
  avatarUrl: Scalars["String"];
  billing?: Maybe<AccountBilling>;
  business?: Maybe<Business>;
  commission?: Maybe<Scalars["Float"]>;
  createdAt: Scalars["String"];
  enabled: Scalars["Boolean"];
  listingCount: Scalars["Float"];
  listings: Array<Listing>;
  primaryUser: User;
  primaryUserId: Scalars["String"];
  stripeAccountId?: Maybe<Scalars["Boolean"]>;
  users: Array<User>;
};

export type AccountListingsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type AccountBilling = {
  __typename?: "AccountBilling";
  budgetMonthlyCents: Scalars["Float"];
  categoryCpc: Array<CategoryCpc>;
  defaultCpc: Scalars["Float"];
};

export type AccountLockedError = GqlError & {
  __typename?: "AccountLockedError";
  code: Scalars["String"];
  message: Scalars["String"];
};

export type AccountNotApprovedError = GqlError & {
  __typename?: "AccountNotApprovedError";
  code: Scalars["String"];
  message: Scalars["String"];
};

export enum AccountType {
  B2c = "b2c",
  C2c = "c2c",
}

export type Attribute = {
  __typename?: "Attribute";
  attributeId: Scalars["String"];
  attributeName: Scalars["String"];
  attributeType: AttributeType;
  categoryIds: Array<Scalars["String"]>;
  required?: Maybe<Scalars["Boolean"]>;
  selectOptions: Array<Scalars["String"]>;
};

export enum AttributeType {
  Boolean = "boolean",
  InputFloat = "input_float",
  InputInt = "input_int",
  InputString = "input_string",
  SelectMulti = "select_multi",
  SelectSingle = "select_single",
}

export type AttributeValue = {
  __typename?: "AttributeValue";
  attributeId: Scalars["String"];
  attributeName: Scalars["String"];
  attributeType: AttributeType;
  valueBoolean?: Maybe<Scalars["Boolean"]>;
  valueFloat?: Maybe<Scalars["Float"]>;
  valueInt?: Maybe<Scalars["Float"]>;
  valueString?: Maybe<Scalars["String"]>;
  valueStringArray?: Maybe<Array<Scalars["String"]>>;
};

export type AttributeValueInput = {
  attributeId: Scalars["String"];
  valueBoolean?: InputMaybe<Scalars["Boolean"]>;
  valueFloat?: InputMaybe<Scalars["Float"]>;
  valueInt?: InputMaybe<Scalars["Float"]>;
  valueString?: InputMaybe<Scalars["String"]>;
  valueStringArray?: InputMaybe<Array<Scalars["String"]>>;
};

export type AuthenticatedUser = {
  __typename?: "AuthenticatedUser";
  _id: Scalars["String"];
  aboutMe?: Maybe<Scalars["String"]>;
  account: Account;
  accountId: Scalars["String"];
  accountName: Scalars["String"];
  avatarURL?: Maybe<Scalars["String"]>;
  contactNumber: Array<ContactNumber>;
  createDate: Scalars["DateTime"];
  email: Scalars["String"];
  emailVerified?: Maybe<Scalars["Boolean"]>;
  enabled: Scalars["Boolean"];
  legalName: LegalName;
  setup: Setup;
  superUser: Scalars["Boolean"];
};

export type AvailabilityResponse = {
  __typename?: "AvailabilityResponse";
  available: Scalars["Boolean"];
  endDate: Scalars["String"];
  startDate: Scalars["String"];
  unavailableDates: Array<Scalars["String"]>;
};

export type AvailableCategory2 = {
  __typename?: "AvailableCategory2";
  categoryId: Scalars["String"];
  categoryName: Scalars["String"];
  depth: Scalars["Float"];
  originalPath: Scalars["String"];
  path: Scalars["String"];
  pathMap: Array<Scalars["String"]>;
  urlSlug: Scalars["String"];
};

export type BookOwnListingError = GqlError & {
  __typename?: "BookOwnListingError";
  code: Scalars["String"];
  message: Scalars["String"];
};

export type Booking = {
  __typename?: "Booking";
  _id: Scalars["String"];
  checkoutId: Scalars["String"];
  dailyListingPrices: DailyPrices;
  dateCreated: Scalars["DateTime"];
  dateEnd: Scalars["DateTime"];
  dateStart: Scalars["DateTime"];
  listing: Listing;
  listingId: Scalars["String"];
  ownerAccount: Account;
  ownerAccountId: Scalars["String"];
  paymentIntentSecret: Scalars["String"];
  refundableBondPrice: Scalars["Float"];
  seekerUser: User;
  seekerUserId: Scalars["String"];
  status: BookingStatus;
  totalListingPrice: Scalars["Float"];
  totalOfferPrice: Scalars["Float"];
};

export type BookingActionResult = Booking | GenericError;

export type BookingHistoryResult = GenericError | OwnerBookingHistory;

export enum BookingStatus {
  Approved = "approved",
  Cancelled = "cancelled",
  Disputed = "disputed",
  OutForRent = "out_for_rent",
  Paid = "paid",
  PaymentFailed = "payment_failed",
  PendingApproval = "pending_approval",
  Rejected = "rejected",
  Returned = "returned",
}

export type BpExportListingsResponse = {
  __typename?: "BpExportListingsResponse";
  csvFileStr: Scalars["String"];
};

export type BpHomepageCategoryTally = {
  __typename?: "BpHomepageCategoryTally";
  category: Category;
  categoryId: Scalars["String"];
  disabledCount: Scalars["Int"];
  enabledCount: Scalars["Int"];
};

export type BpHomepageResponse = {
  __typename?: "BpHomepageResponse";
  categoryTallies: Array<BpHomepageCategoryTally>;
};

export type BpListListingsResponse = {
  __typename?: "BpListListingsResponse";
  page: Array<Listing>;
  totalItems: Scalars["Int"];
};

export type BpOwnerRefExistsResponse = {
  __typename?: "BpOwnerRefExistsResponse";
  exists: Scalars["Boolean"];
  listingId?: Maybe<Scalars["String"]>;
};

export type BrandResponse = {
  __typename?: "BrandResponse";
  brands: Array<Scalars["String"]>;
};

export type Business = {
  __typename?: "Business";
  identifier: Scalars["String"];
  name: Scalars["String"];
};

export type BusinessListingReport = {
  __typename?: "BusinessListingReport";
  clicks?: Maybe<Scalars["Int"]>;
  dateStr: Scalars["String"];
  impressions?: Maybe<Scalars["Int"]>;
  listing: Listing;
  listingId: Scalars["String"];
};

export type Category = {
  __typename?: "Category";
  /** Ordered list of categories to build a breadcrumb, e.g first item > second > third > etc */
  breadcrumb: Array<CategoryBreadCrumb>;
  category: Category;
  categoryId: Scalars["String"];
  categoryName: Scalars["String"];
  /** Un-ordered list of child categories */
  children: Array<Category>;
  isPrimary: Scalars["Boolean"];
  originalPath: Scalars["String"];
  pathMap: Array<Scalars["String"]>;
  urlSlug: Scalars["String"];
};

export type CategoryBreadCrumb = {
  __typename?: "CategoryBreadCrumb";
  categoryId: Scalars["String"];
  categoryName: Scalars["String"];
  urlSlug: Scalars["String"];
};

export type CategoryCpc = {
  __typename?: "CategoryCpc";
  categoryId: Scalars["String"];
  cpc: Scalars["Float"];
  originalPath: Scalars["String"];
};

export type CategoryCpcInput = {
  categoryId: Scalars["String"];
  cpc: Scalars["Float"];
};

export type CategoryPage = {
  __typename?: "CategoryPage";
  /** Ordered list of categories to build a breadcrumb, e.g first item > second > third > etc */
  breadcrumb: Array<CategoryBreadCrumb>;
  categoryId: Scalars["String"];
  categoryName: Scalars["String"];
  /** Un-ordered list of child categories */
  children: Array<CategoryBreadCrumb>;
  urlSlug: Scalars["String"];
};

export type CategoryResult = Category | GenericError;

export type ChatChannel = {
  __typename?: "ChatChannel";
  booking?: Maybe<Booking>;
  bookingId?: Maybe<Scalars["String"]>;
  ctaText: Scalars["String"];
  isOwner: Scalars["Boolean"];
  isSeeker: Scalars["Boolean"];
  listing: Listing;
  listingId: Scalars["String"];
  seekerUser: User;
  seekerUserId: Scalars["String"];
};

export type ChatChannelCreateSuccess = {
  __typename?: "ChatChannelCreateSuccess";
  channelId: Scalars["String"];
  success: Scalars["Boolean"];
};

export type ChatChannelResult = ChatChannel | GenericError;

export type ChatToken = {
  __typename?: "ChatToken";
  token: Scalars["String"];
};

export type ChatTokenResult = ChatToken | GenericError;

export type CheckAccountExistsType = {
  __typename?: "CheckAccountExistsType";
  exists: Scalars["Boolean"];
  provider?: Maybe<Providers>;
};

export type Checkout = {
  __typename?: "Checkout";
  _id: Scalars["String"];
  dailyListingPrices: Array<Scalars["Float"]>;
  dateEnd: Scalars["DateTime"];
  dateStart: Scalars["DateTime"];
  discount: CheckoutDiscount;
  listing: Listing;
  listingId: Scalars["String"];
  ownerMessage: Scalars["String"];
  /** @deprecated No longer used */
  paymentIntentSecret?: Maybe<Scalars["String"]>;
  refundableBondPrice: Scalars["Float"];
  seekerUser: User;
  seekerUserId: Scalars["String"];
  setupIntentSecret: Scalars["String"];
  totalListingPrice: Scalars["Float"];
  totalOfferPrice: Scalars["Float"];
};

export type CheckoutDiscount = {
  __typename?: "CheckoutDiscount";
  creditCents: Scalars["Float"];
};

export type CompleteCheckoutResult = Booking | ListingUnavailableError;

export enum Condition {
  Fair = "FAIR",
  Good = "GOOD",
  Likenew = "LIKENEW",
}

export type ContactNumber = {
  __typename?: "ContactNumber";
  _id: Scalars["String"];
  countryCode: Scalars["String"];
  nextResendAvailable?: Maybe<Scalars["DateTime"]>;
  phoneNumber: Scalars["String"];
  verificationAttempts: Scalars["Float"];
  verified: Scalars["Boolean"];
};

export type ContactNumberInput = {
  countryCode: Scalars["String"];
  mobileNumber: Scalars["String"];
};

export type ContactPage = {
  __typename?: "ContactPage";
  body: Scalars["String"];
  cacheTime: Scalars["Float"];
  heading: Scalars["String"];
};

export type CreateChatChannelResult = ChatChannelCreateSuccess | GenericError;

export type CreateCheckoutResult =
  | BookOwnListingError
  | Checkout
  | ListingNotFoundError
  | ListingUnavailableError;

export type CreateExchangeTokenResult = ExchangeToken | InvalidRefreshTokenError;

export type CreateListingBatchSuccess = {
  __typename?: "CreateListingBatchSuccess";
  batch: Scalars["Boolean"];
  success: Scalars["Boolean"];
};

export type CreateListingResult = CreateListingBatchSuccess | CreateListingSuccess | GenericError;

export type CreateListingSuccess = {
  __typename?: "CreateListingSuccess";
  listing: Listing;
  success: Scalars["Boolean"];
};

export type CreditHistory = {
  __typename?: "CreditHistory";
  createdOn: Scalars["DateTime"];
  creditCents: Scalars["Float"];
  expiryDate?: Maybe<Scalars["DateTime"]>;
  reason: Scalars["String"];
  reference: Scalars["String"];
};

export type DailyPrices = {
  __typename?: "DailyPrices";
  friday?: Maybe<Scalars["Float"]>;
  monday?: Maybe<Scalars["Float"]>;
  saturday?: Maybe<Scalars["Float"]>;
  sunday?: Maybe<Scalars["Float"]>;
  thursday?: Maybe<Scalars["Float"]>;
  tuesday?: Maybe<Scalars["Float"]>;
  wednesday?: Maybe<Scalars["Float"]>;
};

export enum ENotLiveReason {
  AccountNotApproved = "ACCOUNT_NOT_APPROVED",
  AccountStripeTransfers = "ACCOUNT_STRIPE_TRANSFERS",
  Disabled = "DISABLED",
  NotApproved = "NOT_APPROVED",
  NoImage = "NO_IMAGE",
}

export enum ESuListAccountFilterEnum {
  Disputed = "disputed",
  NeedsEnabling = "needs_enabling",
  NewSignups = "new_signups",
  None = "none",
  Popular = "popular",
}

export type EditListingImageInput = {
  _id: Scalars["String"];
  sortOrder: Scalars["Float"];
};

export type EditListingResult = EditListingSuccess | GenericError;

export type EditListingSuccess = {
  __typename?: "EditListingSuccess";
  listing: Listing;
};

export type ExchangeToken = {
  __typename?: "ExchangeToken";
  token: Scalars["String"];
};

export type ExchangeTokenResult = InvalidRefreshTokenError | LoginSuccess;

export type ExternalBankAccount = {
  __typename?: "ExternalBankAccount";
  accountHolderName: Scalars["String"];
  bankAccountId: Scalars["String"];
  bankName: Scalars["String"];
  isPrimary: Scalars["Boolean"];
  last4: Scalars["String"];
};

export type ExternalListing = Listing & {
  __typename?: "ExternalListing";
  _id: Scalars["String"];
  account: Account;
  accountId: Scalars["String"];
  approved: Scalars["Boolean"];
  attributes: Array<AttributeValue>;
  bondPrice: Scalars["Float"];
  brand?: Maybe<Scalars["String"]>;
  category: Category;
  categoryId: Scalars["String"];
  categoryIdPath: Array<Scalars["String"]>;
  description: Scalars["String"];
  enabled: Scalars["Boolean"];
  externalURL?: Maybe<Scalars["String"]>;
  images: Array<ListingImage>;
  keywords: Array<Scalars["String"]>;
  listingId: Scalars["String"];
  listingType: ListingType;
  live: Scalars["Boolean"];
  location: ListingLocation;
  notLiveReason?: Maybe<ENotLiveReason>;
  /** Reference ID from the owner, e.g SKU / Model number */
  ownerRef?: Maybe<Scalars["String"]>;
  ownerUserId: Scalars["String"];
  pricing: Pricing;
  recommendations: Array<ListingRecommendations>;
  rentalDaysMax?: Maybe<Scalars["Int"]>;
  rentalDaysMin?: Maybe<Scalars["Int"]>;
  title: Scalars["String"];
  unavailableDates: UnavailableDatesResponse;
  user: User;
};

export type GenericError = {
  __typename?: "GenericError";
  errorCode: Scalars["String"];
  errorMessage: Scalars["String"];
};

export type GenericResult = GenericError | GenericSuccessType;

export type GenericSuccessType = {
  __typename?: "GenericSuccessType";
  success: Scalars["Boolean"];
};

export type GetCreditResponse = {
  __typename?: "GetCreditResponse";
  creditBalance: Scalars["Float"];
  creditHistory: Array<CreditHistory>;
};

export type GqlError = {
  code: Scalars["String"];
  message: Scalars["String"];
};

export type ImageInput = {
  externalImageURL?: InputMaybe<Scalars["String"]>;
  imageToken?: InputMaybe<Scalars["String"]>;
  sortOrder: Scalars["Float"];
};

export type ImageToken = {
  __typename?: "ImageToken";
  token: Scalars["String"];
};

export type InputValidationError = {
  __typename?: "InputValidationError";
  code: Scalars["String"];
  field: Scalars["String"];
  message: Scalars["String"];
};

export type InputValidationErrors = {
  __typename?: "InputValidationErrors";
  errors: Array<InputValidationError>;
};

export type InternalListing = Listing & {
  __typename?: "InternalListing";
  _id: Scalars["String"];
  account: Account;
  accountId: Scalars["String"];
  approved: Scalars["Boolean"];
  attributes: Array<AttributeValue>;
  bondPrice: Scalars["Float"];
  brand?: Maybe<Scalars["String"]>;
  category: Category;
  categoryId: Scalars["String"];
  categoryIdPath: Array<Scalars["String"]>;
  condition: Condition;
  description: Scalars["String"];
  enabled: Scalars["Boolean"];
  images: Array<ListingImage>;
  keywords: Array<Scalars["String"]>;
  listingId: Scalars["String"];
  listingType: ListingType;
  live: Scalars["Boolean"];
  location: ListingLocation;
  notLiveReason?: Maybe<ENotLiveReason>;
  /** Reference ID from the owner, e.g SKU / Model number */
  ownerRef?: Maybe<Scalars["String"]>;
  ownerUserId: Scalars["String"];
  pricing: Pricing;
  recommendations: Array<ListingRecommendations>;
  rentalDaysMax?: Maybe<Scalars["Int"]>;
  rentalDaysMin?: Maybe<Scalars["Int"]>;
  rules: Array<Scalars["String"]>;
  title: Scalars["String"];
  unavailableDates: UnavailableDatesResponse;
  user: User;
};

export type InvalidEmailOrPasswordError = GqlError & {
  __typename?: "InvalidEmailOrPasswordError";
  code: Scalars["String"];
  message: Scalars["String"];
};

export type InvalidPwResetTokenError = GqlError & {
  __typename?: "InvalidPwResetTokenError";
  code: Scalars["String"];
  message: Scalars["String"];
};

export type InvalidRefreshTokenError = GqlError & {
  __typename?: "InvalidRefreshTokenError";
  code: Scalars["String"];
  message: Scalars["String"];
};

export type LegalName = {
  __typename?: "LegalName";
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  verified: Scalars["Boolean"];
};

export type Listing = {
  _id: Scalars["String"];
  account: Account;
  accountId: Scalars["String"];
  approved: Scalars["Boolean"];
  attributes: Array<AttributeValue>;
  bondPrice: Scalars["Float"];
  brand?: Maybe<Scalars["String"]>;
  category: Category;
  categoryId: Scalars["String"];
  categoryIdPath: Array<Scalars["String"]>;
  description: Scalars["String"];
  enabled: Scalars["Boolean"];
  images: Array<ListingImage>;
  keywords: Array<Scalars["String"]>;
  listingId: Scalars["String"];
  listingType: ListingType;
  live: Scalars["Boolean"];
  location: ListingLocation;
  notLiveReason?: Maybe<ENotLiveReason>;
  /** Reference ID from the owner, e.g SKU / Model number */
  ownerRef?: Maybe<Scalars["String"]>;
  ownerUserId: Scalars["String"];
  pricing: Pricing;
  recommendations: Array<ListingRecommendations>;
  rentalDaysMax?: Maybe<Scalars["Int"]>;
  rentalDaysMin?: Maybe<Scalars["Int"]>;
  title: Scalars["String"];
  unavailableDates: UnavailableDatesResponse;
  user: User;
};

export type ListingImage = {
  __typename?: "ListingImage";
  _id: Scalars["String"];
  blurImage?: Maybe<Scalars["String"]>;
  heroURL?: Maybe<Scalars["String"]>;
  /** Original Image */
  imageURL: Scalars["String"];
  sortOrder: Scalars["Float"];
  thumbnailURL?: Maybe<Scalars["String"]>;
};

export type ListingLocation = {
  __typename?: "ListingLocation";
  latitude?: Maybe<Scalars["Float"]>;
  locality?: Maybe<Scalars["String"]>;
  longitude?: Maybe<Scalars["Float"]>;
  national: Scalars["Boolean"];
  postcode?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
};

export type ListingLocationInput = {
  latitude?: InputMaybe<Scalars["Float"]>;
  locality?: InputMaybe<Scalars["String"]>;
  longitude?: InputMaybe<Scalars["Float"]>;
  national: Scalars["Boolean"];
  postcode?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
};

export type ListingNotFoundError = GqlError & {
  __typename?: "ListingNotFoundError";
  code: Scalars["String"];
  message: Scalars["String"];
};

export type ListingRecommendations = {
  __typename?: "ListingRecommendations";
  heading: Scalars["String"];
  listings: Array<Listing>;
};

export enum ListingSortDirection {
  Ascending = "ascending",
  Descending = "descending",
}

export enum ListingSortOrder {
  CreatedOn = "createdOn",
  ModifiedOn = "modifiedOn",
}

export enum ListingType {
  External = "external",
  Internal = "internal",
}

export type ListingUnavailableError = GqlError & {
  __typename?: "ListingUnavailableError";
  code: Scalars["String"];
  message: Scalars["String"];
};

export type ListingsInput = {
  loggedInUser?: InputMaybe<Scalars["Boolean"]>;
  userId?: InputMaybe<Scalars["String"]>;
};

export type LoginSuccess = {
  __typename?: "LoginSuccess";
  access_token: Scalars["String"];
  account: Account;
  refresh_token: Scalars["String"];
  user: AuthenticatedUser;
};

export type LoginWithPasswordResult =
  | AccountLockedError
  | AccountNotApprovedError
  | InvalidEmailOrPasswordError
  | LoginSuccess;

export type MigrationInvalidListingsResponse = {
  __typename?: "MigrationInvalidListingsResponse";
  invalid: Array<MigrationInvalidListingsResponseListing>;
};

export type MigrationInvalidListingsResponseListing = {
  __typename?: "MigrationInvalidListingsResponseListing";
  error_message?: Maybe<Scalars["String"]>;
  listingId: Scalars["String"];
};

export type MobileVerificationAttemptsExceeded = GqlError & {
  __typename?: "MobileVerificationAttemptsExceeded";
  code: Scalars["String"];
  message: Scalars["String"];
};

export type MobileVerificationCodeNotValidError = GqlError & {
  __typename?: "MobileVerificationCodeNotValidError";
  code: Scalars["String"];
  message: Scalars["String"];
};

export type Mutation = {
  __typename?: "Mutation";
  AddExternalBankAccount: GenericSuccessType;
  BpDisableListing: Listing;
  BpEnableListing: Listing;
  BpMoveListingCategory: Listing;
  BpMoveListingLocation: Listing;
  CaptureOnboardingInformation: OnboardingResponse;
  ChangeEmailAddress: GenericResult;
  CompleteCheckout: CompleteCheckoutResult;
  CreateChannel: CreateChatChannelResult;
  CreateCheckout: CreateCheckoutResult;
  CreateExchangeToken: CreateExchangeTokenResult;
  CreateListing: CreateListingResult;
  CreateSupportRequest: GenericSuccessType;
  DeleteExternalBankAccount: GenericSuccessType;
  ExchangeToken: ExchangeTokenResult;
  LoginWithPassword: LoginWithPasswordResult;
  Logout: Scalars["Boolean"];
  MigrationAccountSetBilling: Scalars["Boolean"];
  MigrationInvalidListings: MigrationInvalidListingsResponse;
  MigrationListingLiveStatus: Scalars["Boolean"];
  MigrationUserSuperUser: Scalars["Boolean"];
  OwnerApproveBooking: BookingActionResult;
  OwnerDisputeBooking: BookingActionResult;
  OwnerRejectBooking: BookingActionResult;
  OwnerReleaseBooking: BookingActionResult;
  OwnerReturnBooking: BookingActionResult;
  RefreshJWT: RefreshJwtResult;
  Register: RegisterResult;
  RegisterPushNotificationToken: GenericSuccessType;
  RequestPasswordReset: Scalars["Boolean"];
  RequestPayout: RequestPayoutResult;
  ResendVerificationCode: GenericResult;
  ResetPassword: ResetPasswordResult;
  SeekerCancelBooking: BookingActionResult;
  SeekerDisputeBooking: BookingActionResult;
  SetExternalBankAccountAsPrimary: GenericSuccessType;
  SuApproveB2cAccount: Account;
  SuApproveListing: Scalars["Boolean"];
  SuConvertAccountC2c: Scalars["Boolean"];
  SuEditUser: Scalars["Boolean"];
  SuSwitchActiveAccount: User;
  SuUnApproveListing: Scalars["Boolean"];
  SuUnapproveB2cAccount: Account;
  SuUpdateBilling: Scalars["Boolean"];
  SuVerifyPhoneNumber: Scalars["Boolean"];
  ToggleListing: Scalars["Boolean"];
  UpdateAccountDetails: Account;
  UpdateAvatar: UpdateAvatarResult;
  UpdateListing: EditListingResult;
  UpdateUser: AuthenticatedUser;
  UploadIdentityDocuments: OnboardingResponse;
  VerifyEmail: Scalars["Boolean"];
  VerifyPhoneNumber: VerifyPhoneNumberResult;
};

export type MutationAddExternalBankAccountArgs = {
  bankAccountToken: Scalars["String"];
};

export type MutationBpDisableListingArgs = {
  listingId: Scalars["String"];
};

export type MutationBpEnableListingArgs = {
  listingId: Scalars["String"];
};

export type MutationBpMoveListingCategoryArgs = {
  categoryId: Scalars["String"];
  listingId: Scalars["String"];
};

export type MutationBpMoveListingLocationArgs = {
  listingId: Scalars["String"];
  location: ListingLocationInput;
};

export type MutationCaptureOnboardingInformationArgs = {
  addressLine1: Scalars["String"];
  addressLine2?: InputMaybe<Scalars["String"]>;
  city: Scalars["String"];
  dateOfBirthDay: Scalars["Int"];
  dateOfBirthMonth: Scalars["Int"];
  dateOfBirthYear: Scalars["Int"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  postalCode: Scalars["String"];
  state: Scalars["String"];
};

export type MutationChangeEmailAddressArgs = {
  email: Scalars["String"];
};

export type MutationCompleteCheckoutArgs = {
  checkoutId: Scalars["String"];
  message?: InputMaybe<Scalars["String"]>;
};

export type MutationCreateChannelArgs = {
  listingId: Scalars["String"];
};

export type MutationCreateCheckoutArgs = {
  dateEnd: Scalars["DateTime"];
  dateStart: Scalars["DateTime"];
  listingId: Scalars["String"];
};

export type MutationCreateExchangeTokenArgs = {
  refreshToken: Scalars["String"];
};

export type MutationCreateListingArgs = {
  accountId?: InputMaybe<Scalars["String"]>;
  attributes: Array<AttributeValueInput>;
  batch?: InputMaybe<Scalars["Boolean"]>;
  bondPrice: Scalars["Float"];
  brand?: InputMaybe<Scalars["String"]>;
  categoryId: Scalars["String"];
  condition?: InputMaybe<Condition>;
  description: Scalars["String"];
  externalURL?: InputMaybe<Scalars["String"]>;
  images: Array<ImageInput>;
  keywords: Array<Scalars["String"]>;
  location?: InputMaybe<ListingLocationInput>;
  maxRentalPeriod?: InputMaybe<Scalars["Float"]>;
  minRentalPeriod?: InputMaybe<Scalars["Float"]>;
  ownerRef?: InputMaybe<Scalars["String"]>;
  pricing: PricingInput;
  rules?: InputMaybe<Array<Scalars["String"]>>;
  title: Scalars["String"];
};

export type MutationCreateSupportRequestArgs = {
  body: Scalars["String"];
  bookingId?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  subject: Scalars["String"];
};

export type MutationDeleteExternalBankAccountArgs = {
  bankAccountId: Scalars["String"];
};

export type MutationExchangeTokenArgs = {
  exchangeToken: Scalars["String"];
};

export type MutationLoginWithPasswordArgs = {
  email: Scalars["String"];
  password: Scalars["String"];
  staySignedIn: Scalars["Boolean"];
};

export type MutationOwnerApproveBookingArgs = {
  bookingId: Scalars["String"];
};

export type MutationOwnerDisputeBookingArgs = {
  bookingId: Scalars["String"];
};

export type MutationOwnerRejectBookingArgs = {
  bookingId: Scalars["String"];
};

export type MutationOwnerReleaseBookingArgs = {
  bookingId: Scalars["String"];
};

export type MutationOwnerReturnBookingArgs = {
  bookingId: Scalars["String"];
};

export type MutationRefreshJwtArgs = {
  refreshToken?: InputMaybe<Scalars["String"]>;
};

export type MutationRegisterArgs = {
  abn?: InputMaybe<Scalars["String"]>;
  accountType: AccountType;
  companyName?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  mobileNumber: ContactNumberInput;
  password: Scalars["String"];
  referrerUserId?: InputMaybe<Scalars["String"]>;
};

export type MutationRegisterPushNotificationTokenArgs = {
  token: Scalars["String"];
};

export type MutationRequestPasswordResetArgs = {
  email: Scalars["String"];
};

export type MutationResendVerificationCodeArgs = {
  contactNumberId: Scalars["String"];
};

export type MutationResetPasswordArgs = {
  newPassword: Scalars["String"];
  token: Scalars["String"];
};

export type MutationSeekerCancelBookingArgs = {
  bookingId: Scalars["String"];
};

export type MutationSeekerDisputeBookingArgs = {
  bookingId: Scalars["String"];
};

export type MutationSetExternalBankAccountAsPrimaryArgs = {
  bankAccountId: Scalars["String"];
};

export type MutationSuApproveB2cAccountArgs = {
  _id: Scalars["String"];
};

export type MutationSuApproveListingArgs = {
  listingId: Scalars["String"];
};

export type MutationSuConvertAccountC2cArgs = {
  accountId: Scalars["String"];
};

export type MutationSuEditUserArgs = {
  aboutMe?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<UserNameInput>;
  userId: Scalars["String"];
};

export type MutationSuSwitchActiveAccountArgs = {
  accountId: Scalars["String"];
};

export type MutationSuUnApproveListingArgs = {
  listingId: Scalars["String"];
};

export type MutationSuUnapproveB2cAccountArgs = {
  _id: Scalars["String"];
};

export type MutationSuUpdateBillingArgs = {
  accountId: Scalars["String"];
  budgetMonthlyCents: Scalars["Float"];
  categoryCpc: Array<CategoryCpcInput>;
  defaultCpc: Scalars["Float"];
};

export type MutationSuVerifyPhoneNumberArgs = {
  contactNumberId: Scalars["String"];
  userId: Scalars["String"];
  verified: Scalars["Boolean"];
};

export type MutationToggleListingArgs = {
  listingId: Scalars["String"];
};

export type MutationUpdateAccountDetailsArgs = {
  abn?: InputMaybe<Scalars["String"]>;
  accountId?: InputMaybe<Scalars["String"]>;
  accountName?: InputMaybe<Scalars["String"]>;
  businessName?: InputMaybe<Scalars["String"]>;
  commission?: InputMaybe<Scalars["Float"]>;
};

export type MutationUpdateAvatarArgs = {
  imageToken: Scalars["String"];
  userId?: InputMaybe<Scalars["String"]>;
};

export type MutationUpdateListingArgs = {
  accountId?: InputMaybe<Scalars["String"]>;
  attributes: Array<AttributeValueInput>;
  batch?: InputMaybe<Scalars["Boolean"]>;
  bondPrice: Scalars["Float"];
  brand?: InputMaybe<Scalars["String"]>;
  categoryId: Scalars["String"];
  condition?: InputMaybe<Condition>;
  description: Scalars["String"];
  editImages: Array<EditListingImageInput>;
  externalURL?: InputMaybe<Scalars["String"]>;
  keywords: Array<Scalars["String"]>;
  listingId: Scalars["String"];
  location?: InputMaybe<ListingLocationInput>;
  maxRentalPeriod?: InputMaybe<Scalars["Float"]>;
  minRentalPeriod?: InputMaybe<Scalars["Float"]>;
  newImages: Array<ImageInput>;
  ownerRef?: InputMaybe<Scalars["String"]>;
  pricing: PricingInput;
  rules?: InputMaybe<Array<Scalars["String"]>>;
  title: Scalars["String"];
};

export type MutationUpdateUserArgs = {
  aboutMe?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
};

export type MutationUploadIdentityDocumentsArgs = {
  back?: InputMaybe<Scalars["String"]>;
  front: Scalars["String"];
};

export type MutationVerifyEmailArgs = {
  token: Scalars["String"];
};

export type MutationVerifyPhoneNumberArgs = {
  contactNumberId: Scalars["String"];
  verificationCode: Scalars["String"];
};

export type MyReferralLink = {
  __typename?: "MyReferralLink";
  link: Scalars["String"];
};

export type Navigation = {
  __typename?: "Navigation";
  cacheTime: Scalars["Float"];
  /** Array of category and an active listing count */
  categories: Array<NavigationTopCategory>;
  /** Array of footer link columns */
  footer: Array<NavigationFooterColumns>;
  /** Array of promo links for the top navigation */
  promoLinks: Array<NavigationLink>;
};

export type NavigationCategory = {
  __typename?: "NavigationCategory";
  /** Categories 1 level down */
  children: Array<NavigationCategory>;
  /** Ordered list of popular child categories (which can be more than 1 level down) by # of listings */
  featured: Array<NavigationLink>;
  href: Scalars["String"];
  title: Scalars["String"];
};

export type NavigationFooterColumns = {
  __typename?: "NavigationFooterColumns";
  /** Ordered list of links */
  links: Array<NavigationLink>;
  title: Scalars["String"];
};

export type NavigationLink = {
  __typename?: "NavigationLink";
  href: Scalars["String"];
  title: Scalars["String"];
};

export type NavigationTopCategory = {
  __typename?: "NavigationTopCategory";
  /** Categories 1 level down */
  children: Array<NavigationCategory>;
  /** Ordered list of popular child categories (which can be more than 1 level down) by # of listings */
  featured: Array<NavigationLink>;
  href: Scalars["String"];
  /** Ordered list of popular brands by # of listings */
  popularBrands: Array<NavigationLink>;
  title: Scalars["String"];
};

export type NoRefreshTokenProvidedError = GqlError & {
  __typename?: "NoRefreshTokenProvidedError";
  code: Scalars["String"];
  message: Scalars["String"];
};

export type OnboardingErrors = {
  __typename?: "OnboardingErrors";
  code: Scalars["String"];
  reason: Scalars["String"];
  requirement: Scalars["String"];
};

export type OnboardingLink = {
  __typename?: "OnboardingLink";
  url: Scalars["String"];
};

export type OnboardingResponse = {
  __typename?: "OnboardingResponse";
  errors: Array<OnboardingErrors>;
  profile: StripeProfileInfo;
  status: StripeStatus;
};

export type OwnerBookingHistory = {
  __typename?: "OwnerBookingHistory";
  current: Array<Booking>;
  past: Array<Booking>;
  upcoming: Array<Booking>;
};

export type Page = {
  __typename?: "Page";
  body: Scalars["String"];
  cacheTime: Scalars["Float"];
  canonicalUrl: Scalars["String"];
  heading: Scalars["String"];
  metaDescription: Scalars["String"];
  publishedTime: Scalars["Float"];
  robotsNoFollow: Scalars["Boolean"];
  robotsNoIndex: Scalars["Boolean"];
  title: Scalars["String"];
  updatedTime: Scalars["Float"];
  urlSlug: Scalars["String"];
};

export type PasswordNotStrongEnoughError = GqlError & {
  __typename?: "PasswordNotStrongEnoughError";
  code: Scalars["String"];
  message: Scalars["String"];
};

export type Pricing = {
  __typename?: "Pricing";
  friday?: Maybe<Scalars["Float"]>;
  monday?: Maybe<Scalars["Float"]>;
  saturday?: Maybe<Scalars["Float"]>;
  sunday?: Maybe<Scalars["Float"]>;
  thursday?: Maybe<Scalars["Float"]>;
  tuesday?: Maybe<Scalars["Float"]>;
  wednesday?: Maybe<Scalars["Float"]>;
};

export type PricingInput = {
  friday?: InputMaybe<Scalars["Float"]>;
  monday?: InputMaybe<Scalars["Float"]>;
  saturday?: InputMaybe<Scalars["Float"]>;
  sunday?: InputMaybe<Scalars["Float"]>;
  thursday?: InputMaybe<Scalars["Float"]>;
  tuesday?: InputMaybe<Scalars["Float"]>;
  wednesday?: InputMaybe<Scalars["Float"]>;
};

export type PricingResponse = {
  __typename?: "PricingResponse";
  bondPrice: Scalars["Int"];
  dailyPrices: DailyPrices;
  endDate: Scalars["String"];
  startDate: Scalars["String"];
  totalPrice: Scalars["Int"];
};

export enum Providers {
  Apple = "apple",
  Facebook = "facebook",
  Google = "google",
  Password = "password",
}

export type Query = {
  __typename?: "Query";
  AboutPage: AboutPage;
  AvailableCategories: Array<CategoryResult>;
  AvailableCategories2: Array<AvailableCategory2>;
  Booking: Booking;
  BpCategoriesSummary: BpHomepageResponse;
  BpExportCsvListings: BpExportListingsResponse;
  BpGetBookings: Array<Booking>;
  BpListListings: BpListListingsResponse;
  BpOwnerRefExists: BpOwnerRefExistsResponse;
  CalculateBookingPrice: PricingResponse;
  Category: CategoryResult;
  CategoryAttributes: Array<Attribute>;
  CategoryPage?: Maybe<CategoryPage>;
  ChatChannel: ChatChannelResult;
  ChatToken: ChatTokenResult;
  CheckAccountExists: CheckAccountExistsType;
  CheckListingAvailability: AvailabilityResponse;
  CheckOnboardingStatus: OnboardingResponse;
  Checkout?: Maybe<Checkout>;
  CommunityCategories?: Maybe<Array<Category>>;
  ContactPage: ContactPage;
  CreateOnboardingLink: OnboardingLink;
  CreateVerificationSession: VerificationSession;
  FetchBusinessListingReports: Array<BusinessListingReport>;
  GetAccount: Account;
  GetAllExternalBankAccounts: Array<ExternalBankAccount>;
  GetBrandPage: BrandResponse;
  GetBrandSlug?: Maybe<Scalars["String"]>;
  GetCredit: GetCreditResponse;
  GetMyReferralLink: MyReferralLink;
  GetReferralHistory: Array<ReferralHistory>;
  GetStripeBalance: StripeBalance;
  GetStripeTransfersStatus?: Maybe<Scalars["String"]>;
  GetUser: User;
  HomePageRecommendations: Array<ListingRecommendations>;
  Listing?: Maybe<Listing>;
  Listings: Array<Listing>;
  LocalityAutocomplete: Array<ListingLocation>;
  LocalityCsvAutocomplete: ListingLocation;
  Me: AuthenticatedUser;
  Navigation: Navigation;
  OwnerBookingHistory: BookingHistoryResult;
  Page: Page;
  RentalHistory: RentalHistoryResult;
  RequestImageToken: Array<ImageToken>;
  SearchConfig: SearchConfig;
  StorePage?: Maybe<StorePage>;
  SuGetAccount: Account;
  SuListAccounts: SuListAccountsResponse;
  User?: Maybe<User>;
};

export type QueryAvailableCategories2Args = {
  depth: Scalars["Int"];
};

export type QueryBookingArgs = {
  bookingId: Scalars["String"];
};

export type QueryBpCategoriesSummaryArgs = {
  accountId?: InputMaybe<Scalars["String"]>;
  allAccounts?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryBpExportCsvListingsArgs = {
  accountId?: InputMaybe<Scalars["String"]>;
  all_accounts?: InputMaybe<Scalars["Boolean"]>;
  approved?: InputMaybe<Scalars["Boolean"]>;
  categoryId?: InputMaybe<Scalars["String"]>;
  enabled?: InputMaybe<Scalars["Boolean"]>;
  listingType?: InputMaybe<ListingType>;
  live?: InputMaybe<Scalars["Boolean"]>;
  location?: InputMaybe<ListingLocationInput>;
  notLiveReason?: InputMaybe<ENotLiveReason>;
  search_term?: InputMaybe<Scalars["String"]>;
};

export type QueryBpGetBookingsArgs = {
  statuses?: InputMaybe<Array<BookingStatus>>;
};

export type QueryBpListListingsArgs = {
  accountId?: InputMaybe<Scalars["String"]>;
  all_accounts?: InputMaybe<Scalars["Boolean"]>;
  approved?: InputMaybe<Scalars["Boolean"]>;
  categoryId?: InputMaybe<Scalars["String"]>;
  enabled?: InputMaybe<Scalars["Boolean"]>;
  limit: Scalars["Int"];
  listingType?: InputMaybe<ListingType>;
  live?: InputMaybe<Scalars["Boolean"]>;
  location?: InputMaybe<ListingLocationInput>;
  notLiveReason?: InputMaybe<ENotLiveReason>;
  offset: Scalars["Int"];
  search_term?: InputMaybe<Scalars["String"]>;
  sort_direction?: InputMaybe<ListingSortDirection>;
  sort_order?: InputMaybe<ListingSortOrder>;
};

export type QueryBpOwnerRefExistsArgs = {
  accountId: Scalars["String"];
  ignoreListingId?: InputMaybe<Scalars["String"]>;
  ownerRef: Scalars["String"];
};

export type QueryCalculateBookingPriceArgs = {
  endDate: Scalars["String"];
  listingId: Scalars["String"];
  startDate: Scalars["String"];
};

export type QueryCategoryArgs = {
  categoryId: Scalars["String"];
};

export type QueryCategoryAttributesArgs = {
  categoryId: Scalars["String"];
};

export type QueryCategoryPageArgs = {
  categoryId?: InputMaybe<Scalars["String"]>;
  urlSlug?: InputMaybe<Scalars["String"]>;
};

export type QueryChatChannelArgs = {
  channelId: Scalars["String"];
};

export type QueryCheckAccountExistsArgs = {
  email: Scalars["String"];
};

export type QueryCheckListingAvailabilityArgs = {
  endDate: Scalars["DateTime"];
  listingId: Scalars["String"];
  startDate: Scalars["DateTime"];
};

export type QueryCheckoutArgs = {
  _id: Scalars["String"];
};

export type QueryCommunityCategoriesArgs = {
  postcodes: Array<Scalars["String"]>;
};

export type QueryCreateOnboardingLinkArgs = {
  refreshURL: Scalars["String"];
  returnURL: Scalars["String"];
};

export type QueryCreateVerificationSessionArgs = {
  checkoutSessionId?: InputMaybe<Scalars["String"]>;
};

export type QueryFetchBusinessListingReportsArgs = {
  accountId?: InputMaybe<Scalars["String"]>;
  endDate: Scalars["DateTime"];
  startDate: Scalars["DateTime"];
};

export type QueryGetAccountArgs = {
  accountId: Scalars["String"];
};

export type QueryGetBrandPageArgs = {
  slug: Scalars["String"];
};

export type QueryGetBrandSlugArgs = {
  brandName: Scalars["String"];
};

export type QueryGetUserArgs = {
  _id: Scalars["String"];
};

export type QueryListingArgs = {
  listingId: Scalars["String"];
};

export type QueryListingsArgs = {
  filter?: InputMaybe<ListingsInput>;
  limit?: InputMaybe<Scalars["Float"]>;
  skip?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryLocalityAutocompleteArgs = {
  localityPartial: Scalars["String"];
};

export type QueryLocalityCsvAutocompleteArgs = {
  postcode: Scalars["String"];
  suburb?: InputMaybe<Scalars["String"]>;
};

export type QueryPageArgs = {
  urlSlug: Scalars["String"];
};

export type QueryRequestImageTokenArgs = {
  qty?: InputMaybe<Scalars["Int"]>;
};

export type QueryStorePageArgs = {
  accountId: Scalars["String"];
};

export type QuerySuGetAccountArgs = {
  _id: Scalars["String"];
};

export type QuerySuListAccountsArgs = {
  filter: ESuListAccountFilterEnum;
  limit: Scalars["Int"];
  offset: Scalars["Int"];
  search_term?: InputMaybe<Scalars["String"]>;
};

export type QueryUserArgs = {
  userId: Scalars["String"];
};

export type ReferralHistory = {
  __typename?: "ReferralHistory";
  createdAt: Scalars["DateTime"];
  creditAmountCents: Scalars["Float"];
  referredUserName: Scalars["String"];
  status: ReferralStatus;
};

export enum ReferralStatus {
  Credited = "credited",
  Expired = "expired",
  Pending = "pending",
}

export type RefreshJwtResult =
  | InvalidRefreshTokenError
  | LoginSuccess
  | NoRefreshTokenProvidedError;

export type RegisterResult = AuthenticatedUser | InputValidationErrors;

export type RentalHistory = {
  __typename?: "RentalHistory";
  current: Array<Booking>;
  past: Array<Booking>;
  upcoming: Array<Booking>;
};

export type RentalHistoryResult = GenericError | RentalHistory;

export type RequestPayoutResult = GenericError | RequestPayoutSuccess;

export type RequestPayoutSuccess = {
  __typename?: "RequestPayoutSuccess";
  arrival_date: Scalars["Float"];
};

export type ResetPasswordResult =
  | InvalidPwResetTokenError
  | PasswordNotStrongEnoughError
  | SuccessfulState;

export type SearchConfig = {
  __typename?: "SearchConfig";
  account: Scalars["String"];
  collection: Scalars["String"];
};

export type Setup = {
  __typename?: "Setup";
  contactNumberVerified: Scalars["Boolean"];
};

export type StorePage = {
  __typename?: "StorePage";
  accountId: Scalars["String"];
  accountName: Scalars["String"];
};

export type StripeBalance = {
  __typename?: "StripeBalance";
  balance: Scalars["Float"];
  pending_balance: Scalars["Float"];
};

export type StripeProfileInfo = {
  __typename?: "StripeProfileInfo";
  addressLine1?: Maybe<Scalars["String"]>;
  addressLine2?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  dateOfBirthDay?: Maybe<Scalars["Int"]>;
  dateOfBirthMonth?: Maybe<Scalars["Int"]>;
  dateOfBirthYear?: Maybe<Scalars["Int"]>;
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  postalCode?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
};

export enum StripeStatus {
  Pending = "pending",
  Unverified = "unverified",
  Verified = "verified",
}

export type SuListAccountsResponse = {
  __typename?: "SuListAccountsResponse";
  page: Array<Account>;
  totalItems: Scalars["Float"];
};

export type SuccessfulState = {
  __typename?: "SuccessfulState";
  success: Scalars["Boolean"];
};

export type UnavailableDatesResponse = {
  __typename?: "UnavailableDatesResponse";
  unavailableDates: Array<Scalars["String"]>;
  unavailableDaysOfWeek: Array<Scalars["Int"]>;
};

export type UpdateAvatarResult = GenericError | UpdateAvatarSuccess;

export type UpdateAvatarSuccess = {
  __typename?: "UpdateAvatarSuccess";
  avatarURL: Scalars["String"];
};

export type User = {
  __typename?: "User";
  _id: Scalars["String"];
  aboutMe?: Maybe<Scalars["String"]>;
  accountId: Scalars["String"];
  accountName: Scalars["String"];
  avatarURL?: Maybe<Scalars["String"]>;
  contactNumber: Array<ContactNumber>;
  createDate: Scalars["DateTime"];
  email: Scalars["String"];
  legalName: LegalName;
  superUser: Scalars["Boolean"];
};

export type UserNameInput = {
  firstName: Scalars["String"];
  lastName: Scalars["String"];
};

export type VerificationSession = {
  __typename?: "VerificationSession";
  clientSecret: Scalars["String"];
  url: Scalars["String"];
};

export type VerifyPhoneNumberResult =
  | MobileVerificationAttemptsExceeded
  | MobileVerificationCodeNotValidError
  | SuccessfulState;

export type NavigationQueryVariables = Exact<{ [key: string]: never }>;

export type NavigationQuery = {
  Navigation: {
    __typename?: "Navigation";
    promoLinks: Array<{ __typename?: "NavigationLink"; title: string; href: string }>;
    categories: Array<{
      __typename: "NavigationTopCategory";
      title: string;
      href: string;
      featured: Array<{ __typename?: "NavigationLink"; title: string; href: string }>;
      popularBrands: Array<{ __typename?: "NavigationLink"; title: string; href: string }>;
      children: Array<{
        __typename?: "NavigationCategory";
        title: string;
        href: string;
        children: Array<{
          __typename?: "NavigationCategory";
          title: string;
          href: string;
          children: Array<{
            __typename?: "NavigationCategory";
            title: string;
            href: string;
            featured: Array<{ __typename?: "NavigationLink"; title: string; href: string }>;
          }>;
          featured: Array<{ __typename?: "NavigationLink"; title: string; href: string }>;
        }>;
        featured: Array<{ __typename?: "NavigationLink"; title: string; href: string }>;
      }>;
    }>;
  };
};

export type NavigationCategoryRecursiveFragment = {
  __typename?: "NavigationCategory";
  title: string;
  href: string;
  featured: Array<{ __typename?: "NavigationLink"; title: string; href: string }>;
};

export type AuthenticatedUserFragment = {
  __typename?: "AuthenticatedUser";
  _id: string;
  avatarURL?: string | null;
  email: string;
  aboutMe?: string | null;
  legalName: { __typename?: "LegalName"; firstName: string; lastName: string; verified: boolean };
  contactNumber: Array<{ __typename?: "ContactNumber"; phoneNumber: string }>;
  setup: { __typename?: "Setup"; contactNumberVerified: boolean };
};

export type LogoutMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutMutation = { Logout: boolean };

export type ExchangeTokenMutationVariables = Exact<{
  exchangeToken: Scalars["String"];
}>;

export type ExchangeTokenMutation = {
  ExchangeToken:
    | { __typename: "InvalidRefreshTokenError"; code: string; message: string }
    | {
        __typename: "LoginSuccess";
        access_token: string;
        refresh_token: string;
        user: {
          __typename?: "AuthenticatedUser";
          _id: string;
          avatarURL?: string | null;
          email: string;
          aboutMe?: string | null;
          legalName: {
            __typename?: "LegalName";
            firstName: string;
            lastName: string;
            verified: boolean;
          };
          contactNumber: Array<{ __typename?: "ContactNumber"; phoneNumber: string }>;
          setup: { __typename?: "Setup"; contactNumberVerified: boolean };
        };
      };
};

export type LoginWithPasswordMutationVariables = Exact<{
  email: Scalars["String"];
  password: Scalars["String"];
  staySignedIn: Scalars["Boolean"];
}>;

export type LoginWithPasswordMutation = {
  LoginWithPassword:
    | { __typename: "AccountLockedError"; code: string; message: string }
    | { __typename: "AccountNotApprovedError"; code: string; message: string }
    | { __typename: "InvalidEmailOrPasswordError"; code: string; message: string }
    | {
        __typename: "LoginSuccess";
        access_token: string;
        refresh_token: string;
        user: {
          __typename?: "AuthenticatedUser";
          _id: string;
          avatarURL?: string | null;
          email: string;
          aboutMe?: string | null;
          legalName: {
            __typename?: "LegalName";
            firstName: string;
            lastName: string;
            verified: boolean;
          };
          contactNumber: Array<{ __typename?: "ContactNumber"; phoneNumber: string }>;
          setup: { __typename?: "Setup"; contactNumberVerified: boolean };
        };
      };
};

export type RefreshJwtMutationVariables = Exact<{
  refreshToken?: InputMaybe<Scalars["String"]>;
}>;

export type RefreshJwtMutation = {
  RefreshJWT:
    | { __typename: "InvalidRefreshTokenError"; code: string; message: string }
    | {
        __typename: "LoginSuccess";
        access_token: string;
        refresh_token: string;
        user: {
          __typename?: "AuthenticatedUser";
          _id: string;
          avatarURL?: string | null;
          email: string;
          aboutMe?: string | null;
          legalName: {
            __typename?: "LegalName";
            firstName: string;
            lastName: string;
            verified: boolean;
          };
          contactNumber: Array<{ __typename?: "ContactNumber"; phoneNumber: string }>;
          setup: { __typename?: "Setup"; contactNumberVerified: boolean };
        };
      }
    | { __typename: "NoRefreshTokenProvidedError"; code: string; message: string };
};

export type RegisterMutationVariables = Exact<{
  email: Scalars["String"];
  password: Scalars["String"];
  lastName: Scalars["String"];
  firstName: Scalars["String"];
  mobileNumber: ContactNumberInput;
  referrerId?: InputMaybe<Scalars["String"]>;
}>;

export type RegisterMutation = {
  Register:
    | {
        __typename: "AuthenticatedUser";
        _id: string;
        avatarURL?: string | null;
        email: string;
        aboutMe?: string | null;
        contactNumber: Array<{ __typename?: "ContactNumber"; _id: string; phoneNumber: string }>;
        legalName: {
          __typename?: "LegalName";
          firstName: string;
          lastName: string;
          verified: boolean;
        };
        setup: { __typename?: "Setup"; contactNumberVerified: boolean };
      }
    | {
        __typename: "InputValidationErrors";
        errors: Array<{ __typename?: "InputValidationError"; field: string; message: string }>;
      };
};

export type VerifyPhoneNumberMutationVariables = Exact<{
  verificationCode: Scalars["String"];
  contactNumberId: Scalars["String"];
}>;

export type VerifyPhoneNumberMutation = {
  VerifyPhoneNumber:
    | { __typename: "MobileVerificationAttemptsExceeded"; code: string; message: string }
    | { __typename: "MobileVerificationCodeNotValidError"; code: string; message: string }
    | { __typename: "SuccessfulState"; success: boolean };
};

export type CheckAccountExistsQueryVariables = Exact<{
  email: Scalars["String"];
}>;

export type CheckAccountExistsQuery = {
  CheckAccountExists: {
    __typename?: "CheckAccountExistsType";
    exists: boolean;
    provider?: Providers | null;
  };
};

export type CompleteCheckoutMutationVariables = Exact<{
  checkoutSessionId: Scalars["String"];
  message?: InputMaybe<Scalars["String"]>;
}>;

export type CompleteCheckoutMutation = {
  completeCheckout:
    | { __typename: "Booking"; _id: string }
    | { __typename: "ListingUnavailableError"; code: string; message: string };
};

export type BookingQueryVariables = Exact<{
  bookingId: Scalars["String"];
}>;

export type BookingQuery = {
  booking: {
    __typename?: "Booking";
    _id: string;
    totalOfferPrice: number;
    refundableBondPrice: number;
    dateStart: any;
    dateEnd: any;
    seeker: { __typename?: "User"; _id: string };
    listing:
      | {
          __typename?: "ExternalListing";
          listingId: string;
          title: string;
          location: {
            __typename?: "ListingLocation";
            locality?: string | null;
            postcode?: string | null;
          };
          images: Array<{ __typename?: "ListingImage"; thumbnailURL?: string | null }>;
          owner: {
            __typename?: "User";
            _id: string;
            avatarURL?: string | null;
            legalName: { __typename?: "LegalName"; firstName: string; verified: boolean };
          };
        }
      | {
          __typename?: "InternalListing";
          listingId: string;
          title: string;
          location: {
            __typename?: "ListingLocation";
            locality?: string | null;
            postcode?: string | null;
          };
          images: Array<{ __typename?: "ListingImage"; thumbnailURL?: string | null }>;
          owner: {
            __typename?: "User";
            _id: string;
            avatarURL?: string | null;
            legalName: { __typename?: "LegalName"; firstName: string; verified: boolean };
          };
        };
  };
};

export type GetCheckoutSessionQueryVariables = Exact<{
  checkoutSessionId: Scalars["String"];
}>;

export type GetCheckoutSessionQuery = {
  checkout?: {
    __typename?: "Checkout";
    _id: string;
    totalOfferPrice: number;
    refundableBondPrice: number;
    dateStart: any;
    dateEnd: any;
    setupIntentSecret: string;
    listing:
      | {
          __typename?: "ExternalListing";
          listingId: string;
          title: string;
          location: {
            __typename?: "ListingLocation";
            locality?: string | null;
            state?: string | null;
          };
          images: Array<{ __typename?: "ListingImage"; thumbnailURL?: string | null }>;
          owner: {
            __typename?: "User";
            _id: string;
            avatarURL?: string | null;
            legalName: { __typename?: "LegalName"; firstName: string; verified: boolean };
          };
        }
      | {
          __typename?: "InternalListing";
          listingId: string;
          title: string;
          location: {
            __typename?: "ListingLocation";
            locality?: string | null;
            state?: string | null;
          };
          images: Array<{ __typename?: "ListingImage"; thumbnailURL?: string | null }>;
          owner: {
            __typename?: "User";
            _id: string;
            avatarURL?: string | null;
            legalName: { __typename?: "LegalName"; firstName: string; verified: boolean };
          };
        };
    seeker: {
      __typename?: "User";
      _id: string;
      email: string;
      legalName: { __typename?: "LegalName"; verified: boolean };
    };
    discount: { __typename?: "CheckoutDiscount"; creditCents: number };
  } | null;
};

export type HomePageRecommendationsQueryVariables = Exact<{ [key: string]: never }>;

export type HomePageRecommendationsQuery = {
  HomePageRecommendations: Array<{
    __typename?: "ListingRecommendations";
    heading: string;
    listings: Array<
      | {
          __typename: "ExternalListing";
          listingId: string;
          title: string;
          images: Array<{ __typename?: "ListingImage"; thumbnailURL?: string | null }>;
          owner: { __typename?: "User"; avatarURL?: string | null };
          location: {
            __typename?: "ListingLocation";
            national: boolean;
            locality?: string | null;
            state?: string | null;
            postcode?: string | null;
          };
          pricing: {
            __typename?: "Pricing";
            monday?: number | null;
            tuesday?: number | null;
            wednesday?: number | null;
            thursday?: number | null;
            friday?: number | null;
            saturday?: number | null;
            sunday?: number | null;
          };
        }
      | {
          __typename: "InternalListing";
          listingId: string;
          title: string;
          images: Array<{ __typename?: "ListingImage"; thumbnailURL?: string | null }>;
          owner: { __typename?: "User"; avatarURL?: string | null };
          location: {
            __typename?: "ListingLocation";
            national: boolean;
            locality?: string | null;
            state?: string | null;
            postcode?: string | null;
          };
          pricing: {
            __typename?: "Pricing";
            monday?: number | null;
            tuesday?: number | null;
            wednesday?: number | null;
            thursday?: number | null;
            friday?: number | null;
            saturday?: number | null;
            sunday?: number | null;
          };
        }
    >;
  }>;
};

export type CheckOnboardingStatusQueryVariables = Exact<{ [key: string]: never }>;

export type CheckOnboardingStatusQuery = {
  OnboardingStatus: {
    __typename?: "OnboardingResponse";
    status: StripeStatus;
    profile: {
      __typename?: "StripeProfileInfo";
      state?: string | null;
      city?: string | null;
      postalCode?: string | null;
      addressLine1?: string | null;
      dateOfBirthYear?: number | null;
      dateOfBirthMonth?: number | null;
      dateOfBirthDay?: number | null;
      lastName: string;
      firstName: string;
    };
    errors: Array<{
      __typename?: "OnboardingErrors";
      code: string;
      requirement: string;
      reason: string;
    }>;
  };
};

export type GetVerificationSessionQueryVariables = Exact<{
  checkoutSessionId?: InputMaybe<Scalars["String"]>;
}>;

export type GetVerificationSessionQuery = {
  verificationSession: { __typename?: "VerificationSession"; url: string; clientSecret: string };
};

export type CaptureOnboardingInfoMutationVariables = Exact<{
  state: Scalars["String"];
  city: Scalars["String"];
  postalCode: Scalars["String"];
  addressLine1: Scalars["String"];
  dateOfBirthYear: Scalars["Int"];
  dateOfBirthMonth: Scalars["Int"];
  dateOfBirthDay: Scalars["Int"];
  lastName: Scalars["String"];
  firstName: Scalars["String"];
}>;

export type CaptureOnboardingInfoMutation = {
  CaptureOnboardingInformation: {
    __typename: "OnboardingResponse";
    errors: Array<{
      __typename?: "OnboardingErrors";
      code: string;
      reason: string;
      requirement: string;
    }>;
  };
};

export type UploadIdDocsMutationVariables = Exact<{
  idDocFrontKey: Scalars["String"];
  idDocBackKey?: InputMaybe<Scalars["String"]>;
}>;

export type UploadIdDocsMutation = {
  UploadIdentityDocuments: {
    __typename?: "OnboardingResponse";
    errors: Array<{
      __typename?: "OnboardingErrors";
      code: string;
      reason: string;
      requirement: string;
    }>;
  };
};

type ListingCardFragment_ExternalListing_ = {
  __typename: "ExternalListing";
  listingId: string;
  title: string;
  images: Array<{ __typename?: "ListingImage"; thumbnailURL?: string | null }>;
  owner: { __typename?: "User"; avatarURL?: string | null };
  location: {
    __typename?: "ListingLocation";
    national: boolean;
    locality?: string | null;
    state?: string | null;
    postcode?: string | null;
  };
  pricing: {
    __typename?: "Pricing";
    monday?: number | null;
    tuesday?: number | null;
    wednesday?: number | null;
    thursday?: number | null;
    friday?: number | null;
    saturday?: number | null;
    sunday?: number | null;
  };
};

type ListingCardFragment_InternalListing_ = {
  __typename: "InternalListing";
  listingId: string;
  title: string;
  images: Array<{ __typename?: "ListingImage"; thumbnailURL?: string | null }>;
  owner: { __typename?: "User"; avatarURL?: string | null };
  location: {
    __typename?: "ListingLocation";
    national: boolean;
    locality?: string | null;
    state?: string | null;
    postcode?: string | null;
  };
  pricing: {
    __typename?: "Pricing";
    monday?: number | null;
    tuesday?: number | null;
    wednesday?: number | null;
    thursday?: number | null;
    friday?: number | null;
    saturday?: number | null;
    sunday?: number | null;
  };
};

export type ListingCardFragment =
  | ListingCardFragment_ExternalListing_
  | ListingCardFragment_InternalListing_;

export type CreateCheckoutMutationVariables = Exact<{
  listingId: Scalars["String"];
  dateStart: Scalars["DateTime"];
  dateEnd: Scalars["DateTime"];
}>;

export type CreateCheckoutMutation = {
  CreateCheckout:
    | { __typename: "BookOwnListingError"; code: string; message: string }
    | { __typename: "Checkout"; _id: string }
    | { __typename: "ListingNotFoundError"; code: string; message: string }
    | { __typename: "ListingUnavailableError"; code: string; message: string };
};

export type CalculateBookingPriceQueryVariables = Exact<{
  listingId: Scalars["String"];
  dateStart: Scalars["String"];
  dateEnd: Scalars["String"];
}>;

export type CalculateBookingPriceQuery = {
  CalculateBookingPrice: { __typename?: "PricingResponse"; totalPrice: number };
};

export type ListingPageQueryVariables = Exact<{
  listingId: Scalars["String"];
}>;

export type ListingPageQuery = {
  listing?:
    | {
        __typename: "ExternalListing";
        externalURL?: string | null;
        listingId: string;
        title: string;
        description: string;
        approved: boolean;
        keywords: Array<string>;
        bondPrice: number;
        brand?: string | null;
        images: Array<{
          __typename?: "ListingImage";
          sortOrder: number;
          thumbnailURL?: string | null;
          heroURL?: string | null;
        }>;
        owner: {
          __typename?: "User";
          _id: string;
          accountId: string;
          avatarURL?: string | null;
          legalName: {
            __typename?: "LegalName";
            firstName: string;
            lastName: string;
            verified: boolean;
          };
        };
        category: {
          __typename?: "Category";
          categoryId: string;
          categoryName: string;
          urlSlug: string;
          breadcrumb: Array<{
            __typename?: "CategoryBreadCrumb";
            categoryName: string;
            urlSlug: string;
          }>;
        };
        pricing: {
          __typename?: "Pricing";
          monday?: number | null;
          tuesday?: number | null;
          wednesday?: number | null;
          thursday?: number | null;
          friday?: number | null;
          saturday?: number | null;
          sunday?: number | null;
        };
        unavailableDates: {
          __typename?: "UnavailableDatesResponse";
          unavailableDates: Array<string>;
          unavailableDaysOfWeek: Array<number>;
        };
        location: {
          __typename?: "ListingLocation";
          national: boolean;
          state?: string | null;
          locality?: string | null;
          postcode?: string | null;
        };
        attributes: Array<{
          __typename?: "AttributeValue";
          attributeId: string;
          attributeType: AttributeType;
          attributeName: string;
          valueInt?: number | null;
          valueFloat?: number | null;
          valueString?: string | null;
          valueStringArray?: Array<string> | null;
          valueBoolean?: boolean | null;
        }>;
        recommendations: Array<{
          __typename?: "ListingRecommendations";
          heading: string;
          listings: Array<
            | {
                __typename: "ExternalListing";
                listingId: string;
                title: string;
                images: Array<{ __typename?: "ListingImage"; thumbnailURL?: string | null }>;
                owner: { __typename?: "User"; avatarURL?: string | null };
                location: {
                  __typename?: "ListingLocation";
                  national: boolean;
                  locality?: string | null;
                  state?: string | null;
                  postcode?: string | null;
                };
                pricing: {
                  __typename?: "Pricing";
                  monday?: number | null;
                  tuesday?: number | null;
                  wednesday?: number | null;
                  thursday?: number | null;
                  friday?: number | null;
                  saturday?: number | null;
                  sunday?: number | null;
                };
              }
            | {
                __typename: "InternalListing";
                listingId: string;
                title: string;
                images: Array<{ __typename?: "ListingImage"; thumbnailURL?: string | null }>;
                owner: { __typename?: "User"; avatarURL?: string | null };
                location: {
                  __typename?: "ListingLocation";
                  national: boolean;
                  locality?: string | null;
                  state?: string | null;
                  postcode?: string | null;
                };
                pricing: {
                  __typename?: "Pricing";
                  monday?: number | null;
                  tuesday?: number | null;
                  wednesday?: number | null;
                  thursday?: number | null;
                  friday?: number | null;
                  saturday?: number | null;
                  sunday?: number | null;
                };
              }
          >;
        }>;
      }
    | {
        __typename: "InternalListing";
        condition: Condition;
        rules: Array<string>;
        listingId: string;
        title: string;
        description: string;
        approved: boolean;
        keywords: Array<string>;
        bondPrice: number;
        brand?: string | null;
        images: Array<{
          __typename?: "ListingImage";
          sortOrder: number;
          thumbnailURL?: string | null;
          heroURL?: string | null;
        }>;
        owner: {
          __typename?: "User";
          _id: string;
          accountId: string;
          avatarURL?: string | null;
          legalName: {
            __typename?: "LegalName";
            firstName: string;
            lastName: string;
            verified: boolean;
          };
        };
        category: {
          __typename?: "Category";
          categoryId: string;
          categoryName: string;
          urlSlug: string;
          breadcrumb: Array<{
            __typename?: "CategoryBreadCrumb";
            categoryName: string;
            urlSlug: string;
          }>;
        };
        pricing: {
          __typename?: "Pricing";
          monday?: number | null;
          tuesday?: number | null;
          wednesday?: number | null;
          thursday?: number | null;
          friday?: number | null;
          saturday?: number | null;
          sunday?: number | null;
        };
        unavailableDates: {
          __typename?: "UnavailableDatesResponse";
          unavailableDates: Array<string>;
          unavailableDaysOfWeek: Array<number>;
        };
        location: {
          __typename?: "ListingLocation";
          national: boolean;
          state?: string | null;
          locality?: string | null;
          postcode?: string | null;
        };
        attributes: Array<{
          __typename?: "AttributeValue";
          attributeId: string;
          attributeType: AttributeType;
          attributeName: string;
          valueInt?: number | null;
          valueFloat?: number | null;
          valueString?: string | null;
          valueStringArray?: Array<string> | null;
          valueBoolean?: boolean | null;
        }>;
        recommendations: Array<{
          __typename?: "ListingRecommendations";
          heading: string;
          listings: Array<
            | {
                __typename: "ExternalListing";
                listingId: string;
                title: string;
                images: Array<{ __typename?: "ListingImage"; thumbnailURL?: string | null }>;
                owner: { __typename?: "User"; avatarURL?: string | null };
                location: {
                  __typename?: "ListingLocation";
                  national: boolean;
                  locality?: string | null;
                  state?: string | null;
                  postcode?: string | null;
                };
                pricing: {
                  __typename?: "Pricing";
                  monday?: number | null;
                  tuesday?: number | null;
                  wednesday?: number | null;
                  thursday?: number | null;
                  friday?: number | null;
                  saturday?: number | null;
                  sunday?: number | null;
                };
              }
            | {
                __typename: "InternalListing";
                listingId: string;
                title: string;
                images: Array<{ __typename?: "ListingImage"; thumbnailURL?: string | null }>;
                owner: { __typename?: "User"; avatarURL?: string | null };
                location: {
                  __typename?: "ListingLocation";
                  national: boolean;
                  locality?: string | null;
                  state?: string | null;
                  postcode?: string | null;
                };
                pricing: {
                  __typename?: "Pricing";
                  monday?: number | null;
                  tuesday?: number | null;
                  wednesday?: number | null;
                  thursday?: number | null;
                  friday?: number | null;
                  saturday?: number | null;
                  sunday?: number | null;
                };
              }
          >;
        }>;
      }
    | null;
};

export type CreditHistoryTable_CreditHistoryFragment = {
  __typename?: "CreditHistory";
  reference: string;
  reason: string;
  creditCents: number;
  createdOn: any;
};

export type GetReferralHistoryQueryVariables = Exact<{ [key: string]: never }>;

export type GetReferralHistoryQuery = {
  GetReferralHistory: Array<{
    __typename?: "ReferralHistory";
    referredUserName: string;
    creditAmountCents: number;
    status: ReferralStatus;
    createdAt: any;
  }>;
};

export type GetBrandPageQueryVariables = Exact<{
  slug: Scalars["String"];
}>;

export type GetBrandPageQuery = {
  GetBrandPage: { __typename?: "BrandResponse"; brands: Array<string> };
};

export type GetCategoryPageQueryVariables = Exact<{
  slug: Scalars["String"];
}>;

export type GetCategoryPageQuery = {
  CategoryPage?: {
    __typename?: "CategoryPage";
    categoryId: string;
    categoryName: string;
    urlSlug: string;
    breadcrumb: Array<{
      __typename?: "CategoryBreadCrumb";
      categoryId: string;
      categoryName: string;
      urlSlug: string;
    }>;
  } | null;
};

export type GetCommunityCategoriesQueryVariables = Exact<{
  postcodes: Array<Scalars["String"]> | Scalars["String"];
}>;

export type GetCommunityCategoriesQuery = {
  CommunityCategories?: Array<{
    __typename?: "Category";
    categoryName: string;
    urlSlug: string;
  }> | null;
};

export type GetStorePageQueryVariables = Exact<{
  accountId: Scalars["String"];
}>;

export type GetStorePageQuery = {
  StorePage?: { __typename?: "StorePage"; accountId: string; accountName: string } | null;
};

export type LocalityAutocompleteQueryVariables = Exact<{
  localityPartial: Scalars["String"];
}>;

export type LocalityAutocompleteQuery = {
  LocalityAutocomplete: Array<{
    __typename?: "ListingLocation";
    postcode?: string | null;
    locality?: string | null;
    state?: string | null;
    latitude?: number | null;
    longitude?: number | null;
  }>;
};

export type VerifyEmailMutationVariables = Exact<{
  token: Scalars["String"];
}>;

export type VerifyEmailMutation = { VerifyEmail: boolean };

export type UserProfileQueryVariables = Exact<{
  userId: Scalars["String"];
}>;

export type UserProfileQuery = {
  user?: {
    __typename?: "User";
    accountId: string;
    createDate: any;
    avatarURL?: string | null;
    aboutMe?: string | null;
    legalName: { __typename?: "LegalName"; firstName: string; verified: boolean };
  } | null;
};

export type ChatChannelQueryVariables = Exact<{
  channelId: Scalars["String"];
}>;

export type ChatChannelQuery = {
  ChatChannel:
    | {
        __typename: "ChatChannel";
        ctaText: string;
        isOwner: boolean;
        isSeeker: boolean;
        listingId: string;
        bookingId?: string | null;
        listing:
          | {
              __typename?: "ExternalListing";
              listingId: string;
              title: string;
              user: {
                __typename?: "User";
                avatarURL?: string | null;
                legalName: { __typename?: "LegalName"; firstName: string };
              };
              images: Array<{ __typename?: "ListingImage"; thumbnailURL?: string | null }>;
            }
          | {
              __typename?: "InternalListing";
              listingId: string;
              title: string;
              user: {
                __typename?: "User";
                avatarURL?: string | null;
                legalName: { __typename?: "LegalName"; firstName: string };
              };
              images: Array<{ __typename?: "ListingImage"; thumbnailURL?: string | null }>;
            };
        seekerUser: {
          __typename?: "User";
          _id: string;
          createDate: any;
          avatarURL?: string | null;
          legalName: {
            __typename?: "LegalName";
            firstName: string;
            lastName: string;
            verified: boolean;
          };
        };
        booking?: { __typename?: "Booking"; dateStart: any; dateEnd: any } | null;
      }
    | { __typename: "GenericError"; errorCode: string; errorMessage: string };
};

export type CreateChannelMutationVariables = Exact<{
  listingId: Scalars["String"];
}>;

export type CreateChannelMutation = {
  CreateChannel:
    | { __typename: "ChatChannelCreateSuccess"; channelId: string }
    | { __typename: "GenericError"; errorCode: string; errorMessage: string };
};

export type ChatTokenQueryVariables = Exact<{ [key: string]: never }>;

export type ChatTokenQuery = {
  ChatToken:
    | { __typename: "ChatToken"; token: string }
    | { __typename: "GenericError"; errorCode: string; errorMessage: string };
};

export type GetCreditQueryVariables = Exact<{ [key: string]: never }>;

export type GetCreditQuery = {
  GetCredit: {
    __typename?: "GetCreditResponse";
    creditBalance: number;
    creditHistory: Array<{
      __typename?: "CreditHistory";
      reference: string;
      reason: string;
      creditCents: number;
      createdOn: any;
    }>;
  };
};

export type GetReferrerQueryVariables = Exact<{
  referrerId: Scalars["String"];
}>;

export type GetReferrerQuery = { Referrer?: { __typename?: "User"; _id: string } | null };

export const NavigationCategoryRecursiveFragmentDoc = `
    fragment NavigationCategoryRecursive on NavigationCategory {
  title
  href
  featured {
    title
    href
  }
}
    `;
export const AuthenticatedUserFragmentDoc = `
    fragment AuthenticatedUserFragment on AuthenticatedUser {
  _id
  avatarURL
  email
  aboutMe
  legalName {
    firstName
    lastName
    verified
  }
  contactNumber {
    phoneNumber
  }
  setup {
    contactNumberVerified
  }
}
    `;
export const ListingCardFragmentDoc = `
    fragment ListingCardFragment on Listing {
  __typename
  listingId
  title
  images {
    thumbnailURL
  }
  owner: user {
    avatarURL
  }
  location {
    national
    locality
    state
    postcode
  }
  pricing {
    monday
    tuesday
    wednesday
    thursday
    friday
    saturday
    sunday
  }
}
    `;
export const CreditHistoryTable_CreditHistoryFragmentDoc = `
    fragment CreditHistoryTable_CreditHistory on CreditHistory {
  reference
  reason
  creditCents
  createdOn
}
    `;
export const NavigationQueryDocument = `
    query NavigationQuery {
  Navigation {
    promoLinks {
      title
      href
    }
    categories {
      __typename
      title
      href
      featured {
        title
        href
      }
      popularBrands {
        title
        href
      }
      children {
        ...NavigationCategoryRecursive
        children {
          ...NavigationCategoryRecursive
          children {
            ...NavigationCategoryRecursive
          }
        }
      }
    }
  }
}
    ${NavigationCategoryRecursiveFragmentDoc}`;
export const useNavigationQuery = <TData = NavigationQuery, TError = SanitizedGQLError>(
  variables?: NavigationQueryVariables,
  options?: UseQueryOptions<NavigationQuery, TError, TData>
) =>
  useQuery<NavigationQuery, TError, TData>(
    variables === undefined ? ["NavigationQuery"] : ["NavigationQuery", variables],
    fetcher<NavigationQuery, NavigationQueryVariables>(NavigationQueryDocument, variables),
    options
  );

useNavigationQuery.getKey = (variables?: NavigationQueryVariables) =>
  variables === undefined ? ["NavigationQuery"] : ["NavigationQuery", variables];
useNavigationQuery.fetcher = (
  variables?: NavigationQueryVariables,
  options?: RequestInit["headers"]
) =>
  fetcher<NavigationQuery, NavigationQueryVariables>(NavigationQueryDocument, variables, options);
export const LogoutDocument = `
    mutation Logout {
  Logout
}
    `;
export const useLogoutMutation = <TError = SanitizedGQLError, TContext = unknown>(
  options?: UseMutationOptions<LogoutMutation, TError, LogoutMutationVariables, TContext>
) =>
  useMutation<LogoutMutation, TError, LogoutMutationVariables, TContext>(
    ["Logout"],
    (variables?: LogoutMutationVariables) =>
      fetcher<LogoutMutation, LogoutMutationVariables>(LogoutDocument, variables)(),
    options
  );
useLogoutMutation.getKey = () => ["Logout"];

useLogoutMutation.fetcher = (
  variables?: LogoutMutationVariables,
  options?: RequestInit["headers"]
) => fetcher<LogoutMutation, LogoutMutationVariables>(LogoutDocument, variables, options);
export const ExchangeTokenDocument = `
    mutation ExchangeToken($exchangeToken: String!) {
  ExchangeToken(exchangeToken: $exchangeToken) {
    __typename
    ... on LoginSuccess {
      access_token
      refresh_token
      user {
        ...AuthenticatedUserFragment
      }
    }
    ... on GqlError {
      code
      message
    }
  }
}
    ${AuthenticatedUserFragmentDoc}`;
export const useExchangeTokenMutation = <TError = SanitizedGQLError, TContext = unknown>(
  options?: UseMutationOptions<
    ExchangeTokenMutation,
    TError,
    ExchangeTokenMutationVariables,
    TContext
  >
) =>
  useMutation<ExchangeTokenMutation, TError, ExchangeTokenMutationVariables, TContext>(
    ["ExchangeToken"],
    (variables?: ExchangeTokenMutationVariables) =>
      fetcher<ExchangeTokenMutation, ExchangeTokenMutationVariables>(
        ExchangeTokenDocument,
        variables
      )(),
    options
  );
useExchangeTokenMutation.getKey = () => ["ExchangeToken"];

useExchangeTokenMutation.fetcher = (
  variables: ExchangeTokenMutationVariables,
  options?: RequestInit["headers"]
) =>
  fetcher<ExchangeTokenMutation, ExchangeTokenMutationVariables>(
    ExchangeTokenDocument,
    variables,
    options
  );
export const LoginWithPasswordDocument = `
    mutation LoginWithPassword($email: String!, $password: String!, $staySignedIn: Boolean!) {
  LoginWithPassword(
    email: $email
    password: $password
    staySignedIn: $staySignedIn
  ) {
    __typename
    ... on LoginSuccess {
      access_token
      refresh_token
      user {
        ...AuthenticatedUserFragment
      }
    }
    ... on GqlError {
      code
      message
    }
  }
}
    ${AuthenticatedUserFragmentDoc}`;
export const useLoginWithPasswordMutation = <TError = SanitizedGQLError, TContext = unknown>(
  options?: UseMutationOptions<
    LoginWithPasswordMutation,
    TError,
    LoginWithPasswordMutationVariables,
    TContext
  >
) =>
  useMutation<LoginWithPasswordMutation, TError, LoginWithPasswordMutationVariables, TContext>(
    ["LoginWithPassword"],
    (variables?: LoginWithPasswordMutationVariables) =>
      fetcher<LoginWithPasswordMutation, LoginWithPasswordMutationVariables>(
        LoginWithPasswordDocument,
        variables
      )(),
    options
  );
useLoginWithPasswordMutation.getKey = () => ["LoginWithPassword"];

useLoginWithPasswordMutation.fetcher = (
  variables: LoginWithPasswordMutationVariables,
  options?: RequestInit["headers"]
) =>
  fetcher<LoginWithPasswordMutation, LoginWithPasswordMutationVariables>(
    LoginWithPasswordDocument,
    variables,
    options
  );
export const RefreshJwtDocument = `
    mutation RefreshJWT($refreshToken: String) {
  RefreshJWT(refreshToken: $refreshToken) {
    __typename
    ... on LoginSuccess {
      access_token
      refresh_token
      user {
        ...AuthenticatedUserFragment
      }
    }
    ... on GqlError {
      code
      message
    }
  }
}
    ${AuthenticatedUserFragmentDoc}`;
export const useRefreshJwtMutation = <TError = SanitizedGQLError, TContext = unknown>(
  options?: UseMutationOptions<RefreshJwtMutation, TError, RefreshJwtMutationVariables, TContext>
) =>
  useMutation<RefreshJwtMutation, TError, RefreshJwtMutationVariables, TContext>(
    ["RefreshJWT"],
    (variables?: RefreshJwtMutationVariables) =>
      fetcher<RefreshJwtMutation, RefreshJwtMutationVariables>(RefreshJwtDocument, variables)(),
    options
  );
useRefreshJwtMutation.getKey = () => ["RefreshJWT"];

useRefreshJwtMutation.fetcher = (
  variables?: RefreshJwtMutationVariables,
  options?: RequestInit["headers"]
) =>
  fetcher<RefreshJwtMutation, RefreshJwtMutationVariables>(RefreshJwtDocument, variables, options);
export const RegisterDocument = `
    mutation Register($email: String!, $password: String!, $lastName: String!, $firstName: String!, $mobileNumber: ContactNumberInput!, $referrerId: String) {
  Register(
    email: $email
    password: $password
    lastName: $lastName
    firstName: $firstName
    mobileNumber: $mobileNumber
    referrerUserId: $referrerId
    accountType: c2c
  ) {
    __typename
    ... on AuthenticatedUser {
      ...AuthenticatedUserFragment
      contactNumber {
        _id
      }
    }
    ... on InputValidationErrors {
      errors {
        field
        message
      }
    }
  }
}
    ${AuthenticatedUserFragmentDoc}`;
export const useRegisterMutation = <TError = SanitizedGQLError, TContext = unknown>(
  options?: UseMutationOptions<RegisterMutation, TError, RegisterMutationVariables, TContext>
) =>
  useMutation<RegisterMutation, TError, RegisterMutationVariables, TContext>(
    ["Register"],
    (variables?: RegisterMutationVariables) =>
      fetcher<RegisterMutation, RegisterMutationVariables>(RegisterDocument, variables)(),
    options
  );
useRegisterMutation.getKey = () => ["Register"];

useRegisterMutation.fetcher = (
  variables: RegisterMutationVariables,
  options?: RequestInit["headers"]
) => fetcher<RegisterMutation, RegisterMutationVariables>(RegisterDocument, variables, options);
export const VerifyPhoneNumberDocument = `
    mutation VerifyPhoneNumber($verificationCode: String!, $contactNumberId: String!) {
  VerifyPhoneNumber(
    verificationCode: $verificationCode
    contactNumberId: $contactNumberId
  ) {
    __typename
    ... on SuccessfulState {
      success
    }
    ... on GqlError {
      code
      message
    }
  }
}
    `;
export const useVerifyPhoneNumberMutation = <TError = SanitizedGQLError, TContext = unknown>(
  options?: UseMutationOptions<
    VerifyPhoneNumberMutation,
    TError,
    VerifyPhoneNumberMutationVariables,
    TContext
  >
) =>
  useMutation<VerifyPhoneNumberMutation, TError, VerifyPhoneNumberMutationVariables, TContext>(
    ["VerifyPhoneNumber"],
    (variables?: VerifyPhoneNumberMutationVariables) =>
      fetcher<VerifyPhoneNumberMutation, VerifyPhoneNumberMutationVariables>(
        VerifyPhoneNumberDocument,
        variables
      )(),
    options
  );
useVerifyPhoneNumberMutation.getKey = () => ["VerifyPhoneNumber"];

useVerifyPhoneNumberMutation.fetcher = (
  variables: VerifyPhoneNumberMutationVariables,
  options?: RequestInit["headers"]
) =>
  fetcher<VerifyPhoneNumberMutation, VerifyPhoneNumberMutationVariables>(
    VerifyPhoneNumberDocument,
    variables,
    options
  );
export const CheckAccountExistsDocument = `
    query CheckAccountExists($email: String!) {
  CheckAccountExists(email: $email) {
    exists
    provider
  }
}
    `;
export const useCheckAccountExistsQuery = <
  TData = CheckAccountExistsQuery,
  TError = SanitizedGQLError
>(
  variables: CheckAccountExistsQueryVariables,
  options?: UseQueryOptions<CheckAccountExistsQuery, TError, TData>
) =>
  useQuery<CheckAccountExistsQuery, TError, TData>(
    ["CheckAccountExists", variables],
    fetcher<CheckAccountExistsQuery, CheckAccountExistsQueryVariables>(
      CheckAccountExistsDocument,
      variables
    ),
    options
  );

useCheckAccountExistsQuery.getKey = (variables: CheckAccountExistsQueryVariables) => [
  "CheckAccountExists",
  variables,
];
useCheckAccountExistsQuery.fetcher = (
  variables: CheckAccountExistsQueryVariables,
  options?: RequestInit["headers"]
) =>
  fetcher<CheckAccountExistsQuery, CheckAccountExistsQueryVariables>(
    CheckAccountExistsDocument,
    variables,
    options
  );
export const CompleteCheckoutDocument = `
    mutation CompleteCheckout($checkoutSessionId: String!, $message: String) {
  completeCheckout: CompleteCheckout(
    checkoutId: $checkoutSessionId
    message: $message
  ) {
    __typename
    ... on Booking {
      _id
    }
    ... on GqlError {
      code
      message
    }
  }
}
    `;
export const useCompleteCheckoutMutation = <TError = SanitizedGQLError, TContext = unknown>(
  options?: UseMutationOptions<
    CompleteCheckoutMutation,
    TError,
    CompleteCheckoutMutationVariables,
    TContext
  >
) =>
  useMutation<CompleteCheckoutMutation, TError, CompleteCheckoutMutationVariables, TContext>(
    ["CompleteCheckout"],
    (variables?: CompleteCheckoutMutationVariables) =>
      fetcher<CompleteCheckoutMutation, CompleteCheckoutMutationVariables>(
        CompleteCheckoutDocument,
        variables
      )(),
    options
  );
useCompleteCheckoutMutation.getKey = () => ["CompleteCheckout"];

useCompleteCheckoutMutation.fetcher = (
  variables: CompleteCheckoutMutationVariables,
  options?: RequestInit["headers"]
) =>
  fetcher<CompleteCheckoutMutation, CompleteCheckoutMutationVariables>(
    CompleteCheckoutDocument,
    variables,
    options
  );
export const BookingDocument = `
    query Booking($bookingId: String!) {
  booking: Booking(bookingId: $bookingId) {
    _id
    seeker: seekerUser {
      _id
    }
    listing {
      listingId
      title
      location {
        locality
        postcode
      }
      images {
        thumbnailURL
      }
      owner: user {
        _id
        legalName {
          firstName
          verified
        }
        avatarURL
      }
    }
    totalOfferPrice
    refundableBondPrice
    dateStart
    dateEnd
  }
}
    `;
export const useBookingQuery = <TData = BookingQuery, TError = SanitizedGQLError>(
  variables: BookingQueryVariables,
  options?: UseQueryOptions<BookingQuery, TError, TData>
) =>
  useQuery<BookingQuery, TError, TData>(
    ["Booking", variables],
    fetcher<BookingQuery, BookingQueryVariables>(BookingDocument, variables),
    options
  );

useBookingQuery.getKey = (variables: BookingQueryVariables) => ["Booking", variables];
useBookingQuery.fetcher = (variables: BookingQueryVariables, options?: RequestInit["headers"]) =>
  fetcher<BookingQuery, BookingQueryVariables>(BookingDocument, variables, options);
export const GetCheckoutSessionDocument = `
    query GetCheckoutSession($checkoutSessionId: String!) {
  checkout: Checkout(_id: $checkoutSessionId) {
    _id
    listing {
      listingId
      title
      location {
        locality
        state
      }
      images {
        thumbnailURL
      }
      owner: user {
        _id
        legalName {
          firstName
          verified
        }
        avatarURL
      }
    }
    seeker: seekerUser {
      _id
      email
      legalName {
        verified
      }
    }
    totalOfferPrice
    refundableBondPrice
    dateStart
    dateEnd
    setupIntentSecret
    discount {
      creditCents
    }
  }
}
    `;
export const useGetCheckoutSessionQuery = <
  TData = GetCheckoutSessionQuery,
  TError = SanitizedGQLError
>(
  variables: GetCheckoutSessionQueryVariables,
  options?: UseQueryOptions<GetCheckoutSessionQuery, TError, TData>
) =>
  useQuery<GetCheckoutSessionQuery, TError, TData>(
    ["GetCheckoutSession", variables],
    fetcher<GetCheckoutSessionQuery, GetCheckoutSessionQueryVariables>(
      GetCheckoutSessionDocument,
      variables
    ),
    options
  );

useGetCheckoutSessionQuery.getKey = (variables: GetCheckoutSessionQueryVariables) => [
  "GetCheckoutSession",
  variables,
];
useGetCheckoutSessionQuery.fetcher = (
  variables: GetCheckoutSessionQueryVariables,
  options?: RequestInit["headers"]
) =>
  fetcher<GetCheckoutSessionQuery, GetCheckoutSessionQueryVariables>(
    GetCheckoutSessionDocument,
    variables,
    options
  );
export const HomePageRecommendationsDocument = `
    query HomePageRecommendations {
  HomePageRecommendations {
    heading
    listings {
      ...ListingCardFragment
    }
  }
}
    ${ListingCardFragmentDoc}`;
export const useHomePageRecommendationsQuery = <
  TData = HomePageRecommendationsQuery,
  TError = SanitizedGQLError
>(
  variables?: HomePageRecommendationsQueryVariables,
  options?: UseQueryOptions<HomePageRecommendationsQuery, TError, TData>
) =>
  useQuery<HomePageRecommendationsQuery, TError, TData>(
    variables === undefined ? ["HomePageRecommendations"] : ["HomePageRecommendations", variables],
    fetcher<HomePageRecommendationsQuery, HomePageRecommendationsQueryVariables>(
      HomePageRecommendationsDocument,
      variables
    ),
    options
  );

useHomePageRecommendationsQuery.getKey = (variables?: HomePageRecommendationsQueryVariables) =>
  variables === undefined ? ["HomePageRecommendations"] : ["HomePageRecommendations", variables];
useHomePageRecommendationsQuery.fetcher = (
  variables?: HomePageRecommendationsQueryVariables,
  options?: RequestInit["headers"]
) =>
  fetcher<HomePageRecommendationsQuery, HomePageRecommendationsQueryVariables>(
    HomePageRecommendationsDocument,
    variables,
    options
  );
export const CheckOnboardingStatusDocument = `
    query CheckOnboardingStatus {
  OnboardingStatus: CheckOnboardingStatus {
    status
    profile {
      state
      city
      postalCode
      addressLine1
      dateOfBirthYear
      dateOfBirthMonth
      dateOfBirthDay
      lastName
      firstName
    }
    errors {
      code
      requirement
      reason
    }
  }
}
    `;
export const useCheckOnboardingStatusQuery = <
  TData = CheckOnboardingStatusQuery,
  TError = SanitizedGQLError
>(
  variables?: CheckOnboardingStatusQueryVariables,
  options?: UseQueryOptions<CheckOnboardingStatusQuery, TError, TData>
) =>
  useQuery<CheckOnboardingStatusQuery, TError, TData>(
    variables === undefined ? ["CheckOnboardingStatus"] : ["CheckOnboardingStatus", variables],
    fetcher<CheckOnboardingStatusQuery, CheckOnboardingStatusQueryVariables>(
      CheckOnboardingStatusDocument,
      variables
    ),
    options
  );

useCheckOnboardingStatusQuery.getKey = (variables?: CheckOnboardingStatusQueryVariables) =>
  variables === undefined ? ["CheckOnboardingStatus"] : ["CheckOnboardingStatus", variables];
useCheckOnboardingStatusQuery.fetcher = (
  variables?: CheckOnboardingStatusQueryVariables,
  options?: RequestInit["headers"]
) =>
  fetcher<CheckOnboardingStatusQuery, CheckOnboardingStatusQueryVariables>(
    CheckOnboardingStatusDocument,
    variables,
    options
  );
export const GetVerificationSessionDocument = `
    query GetVerificationSession($checkoutSessionId: String) {
  verificationSession: CreateVerificationSession(
    checkoutSessionId: $checkoutSessionId
  ) {
    url
    clientSecret
  }
}
    `;
export const useGetVerificationSessionQuery = <
  TData = GetVerificationSessionQuery,
  TError = SanitizedGQLError
>(
  variables?: GetVerificationSessionQueryVariables,
  options?: UseQueryOptions<GetVerificationSessionQuery, TError, TData>
) =>
  useQuery<GetVerificationSessionQuery, TError, TData>(
    variables === undefined ? ["GetVerificationSession"] : ["GetVerificationSession", variables],
    fetcher<GetVerificationSessionQuery, GetVerificationSessionQueryVariables>(
      GetVerificationSessionDocument,
      variables
    ),
    options
  );

useGetVerificationSessionQuery.getKey = (variables?: GetVerificationSessionQueryVariables) =>
  variables === undefined ? ["GetVerificationSession"] : ["GetVerificationSession", variables];
useGetVerificationSessionQuery.fetcher = (
  variables?: GetVerificationSessionQueryVariables,
  options?: RequestInit["headers"]
) =>
  fetcher<GetVerificationSessionQuery, GetVerificationSessionQueryVariables>(
    GetVerificationSessionDocument,
    variables,
    options
  );
export const CaptureOnboardingInfoDocument = `
    mutation CaptureOnboardingInfo($state: String!, $city: String!, $postalCode: String!, $addressLine1: String!, $dateOfBirthYear: Int!, $dateOfBirthMonth: Int!, $dateOfBirthDay: Int!, $lastName: String!, $firstName: String!) {
  CaptureOnboardingInformation(
    state: $state
    city: $city
    postalCode: $postalCode
    addressLine1: $addressLine1
    dateOfBirthYear: $dateOfBirthYear
    dateOfBirthMonth: $dateOfBirthMonth
    dateOfBirthDay: $dateOfBirthDay
    lastName: $lastName
    firstName: $firstName
  ) {
    __typename
    errors {
      code
      reason
      requirement
    }
  }
}
    `;
export const useCaptureOnboardingInfoMutation = <TError = SanitizedGQLError, TContext = unknown>(
  options?: UseMutationOptions<
    CaptureOnboardingInfoMutation,
    TError,
    CaptureOnboardingInfoMutationVariables,
    TContext
  >
) =>
  useMutation<
    CaptureOnboardingInfoMutation,
    TError,
    CaptureOnboardingInfoMutationVariables,
    TContext
  >(
    ["CaptureOnboardingInfo"],
    (variables?: CaptureOnboardingInfoMutationVariables) =>
      fetcher<CaptureOnboardingInfoMutation, CaptureOnboardingInfoMutationVariables>(
        CaptureOnboardingInfoDocument,
        variables
      )(),
    options
  );
useCaptureOnboardingInfoMutation.getKey = () => ["CaptureOnboardingInfo"];

useCaptureOnboardingInfoMutation.fetcher = (
  variables: CaptureOnboardingInfoMutationVariables,
  options?: RequestInit["headers"]
) =>
  fetcher<CaptureOnboardingInfoMutation, CaptureOnboardingInfoMutationVariables>(
    CaptureOnboardingInfoDocument,
    variables,
    options
  );
export const UploadIdDocsDocument = `
    mutation UploadIDDocs($idDocFrontKey: String!, $idDocBackKey: String) {
  UploadIdentityDocuments(front: $idDocFrontKey, back: $idDocBackKey) {
    errors {
      code
      reason
      requirement
    }
  }
}
    `;
export const useUploadIdDocsMutation = <TError = SanitizedGQLError, TContext = unknown>(
  options?: UseMutationOptions<
    UploadIdDocsMutation,
    TError,
    UploadIdDocsMutationVariables,
    TContext
  >
) =>
  useMutation<UploadIdDocsMutation, TError, UploadIdDocsMutationVariables, TContext>(
    ["UploadIDDocs"],
    (variables?: UploadIdDocsMutationVariables) =>
      fetcher<UploadIdDocsMutation, UploadIdDocsMutationVariables>(
        UploadIdDocsDocument,
        variables
      )(),
    options
  );
useUploadIdDocsMutation.getKey = () => ["UploadIDDocs"];

useUploadIdDocsMutation.fetcher = (
  variables: UploadIdDocsMutationVariables,
  options?: RequestInit["headers"]
) =>
  fetcher<UploadIdDocsMutation, UploadIdDocsMutationVariables>(
    UploadIdDocsDocument,
    variables,
    options
  );
export const CreateCheckoutDocument = `
    mutation CreateCheckout($listingId: String!, $dateStart: DateTime!, $dateEnd: DateTime!) {
  CreateCheckout(listingId: $listingId, dateStart: $dateStart, dateEnd: $dateEnd) {
    __typename
    ... on Checkout {
      _id
    }
    ... on GqlError {
      code
      message
    }
  }
}
    `;
export const useCreateCheckoutMutation = <TError = SanitizedGQLError, TContext = unknown>(
  options?: UseMutationOptions<
    CreateCheckoutMutation,
    TError,
    CreateCheckoutMutationVariables,
    TContext
  >
) =>
  useMutation<CreateCheckoutMutation, TError, CreateCheckoutMutationVariables, TContext>(
    ["CreateCheckout"],
    (variables?: CreateCheckoutMutationVariables) =>
      fetcher<CreateCheckoutMutation, CreateCheckoutMutationVariables>(
        CreateCheckoutDocument,
        variables
      )(),
    options
  );
useCreateCheckoutMutation.getKey = () => ["CreateCheckout"];

useCreateCheckoutMutation.fetcher = (
  variables: CreateCheckoutMutationVariables,
  options?: RequestInit["headers"]
) =>
  fetcher<CreateCheckoutMutation, CreateCheckoutMutationVariables>(
    CreateCheckoutDocument,
    variables,
    options
  );
export const CalculateBookingPriceDocument = `
    query CalculateBookingPrice($listingId: String!, $dateStart: String!, $dateEnd: String!) {
  CalculateBookingPrice(
    listingId: $listingId
    startDate: $dateStart
    endDate: $dateEnd
  ) {
    totalPrice
  }
}
    `;
export const useCalculateBookingPriceQuery = <
  TData = CalculateBookingPriceQuery,
  TError = SanitizedGQLError
>(
  variables: CalculateBookingPriceQueryVariables,
  options?: UseQueryOptions<CalculateBookingPriceQuery, TError, TData>
) =>
  useQuery<CalculateBookingPriceQuery, TError, TData>(
    ["CalculateBookingPrice", variables],
    fetcher<CalculateBookingPriceQuery, CalculateBookingPriceQueryVariables>(
      CalculateBookingPriceDocument,
      variables
    ),
    options
  );

useCalculateBookingPriceQuery.getKey = (variables: CalculateBookingPriceQueryVariables) => [
  "CalculateBookingPrice",
  variables,
];
useCalculateBookingPriceQuery.fetcher = (
  variables: CalculateBookingPriceQueryVariables,
  options?: RequestInit["headers"]
) =>
  fetcher<CalculateBookingPriceQuery, CalculateBookingPriceQueryVariables>(
    CalculateBookingPriceDocument,
    variables,
    options
  );
export const ListingPageDocument = `
    query ListingPage($listingId: String!) {
  listing: Listing(listingId: $listingId) {
    __typename
    listingId
    title
    description
    approved
    keywords
    images {
      sortOrder
      thumbnailURL
      heroURL
    }
    owner: user {
      _id
      accountId
      avatarURL
      legalName {
        firstName
        lastName
        verified
      }
    }
    category {
      categoryId
      categoryName
      urlSlug
      breadcrumb {
        categoryName
        urlSlug
      }
    }
    bondPrice
    pricing {
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      sunday
    }
    unavailableDates {
      unavailableDates
      unavailableDaysOfWeek
    }
    location {
      national
      state
      locality
      postcode
    }
    brand
    attributes {
      attributeId
      attributeType
      attributeName
      valueInt
      valueFloat
      valueString
      valueStringArray
      valueBoolean
    }
    recommendations {
      heading
      listings {
        ...ListingCardFragment
      }
    }
    ... on InternalListing {
      condition
      rules
    }
    ... on ExternalListing {
      externalURL
    }
  }
}
    ${ListingCardFragmentDoc}`;
export const useListingPageQuery = <TData = ListingPageQuery, TError = SanitizedGQLError>(
  variables: ListingPageQueryVariables,
  options?: UseQueryOptions<ListingPageQuery, TError, TData>
) =>
  useQuery<ListingPageQuery, TError, TData>(
    ["ListingPage", variables],
    fetcher<ListingPageQuery, ListingPageQueryVariables>(ListingPageDocument, variables),
    options
  );

useListingPageQuery.getKey = (variables: ListingPageQueryVariables) => ["ListingPage", variables];
useListingPageQuery.fetcher = (
  variables: ListingPageQueryVariables,
  options?: RequestInit["headers"]
) => fetcher<ListingPageQuery, ListingPageQueryVariables>(ListingPageDocument, variables, options);
export const GetReferralHistoryDocument = `
    query GetReferralHistory {
  GetReferralHistory {
    referredUserName
    creditAmountCents
    status
    createdAt
  }
}
    `;
export const useGetReferralHistoryQuery = <
  TData = GetReferralHistoryQuery,
  TError = SanitizedGQLError
>(
  variables?: GetReferralHistoryQueryVariables,
  options?: UseQueryOptions<GetReferralHistoryQuery, TError, TData>
) =>
  useQuery<GetReferralHistoryQuery, TError, TData>(
    variables === undefined ? ["GetReferralHistory"] : ["GetReferralHistory", variables],
    fetcher<GetReferralHistoryQuery, GetReferralHistoryQueryVariables>(
      GetReferralHistoryDocument,
      variables
    ),
    options
  );

useGetReferralHistoryQuery.getKey = (variables?: GetReferralHistoryQueryVariables) =>
  variables === undefined ? ["GetReferralHistory"] : ["GetReferralHistory", variables];
useGetReferralHistoryQuery.fetcher = (
  variables?: GetReferralHistoryQueryVariables,
  options?: RequestInit["headers"]
) =>
  fetcher<GetReferralHistoryQuery, GetReferralHistoryQueryVariables>(
    GetReferralHistoryDocument,
    variables,
    options
  );
export const GetBrandPageDocument = `
    query GetBrandPage($slug: String!) {
  GetBrandPage(slug: $slug) {
    brands
  }
}
    `;
export const useGetBrandPageQuery = <TData = GetBrandPageQuery, TError = SanitizedGQLError>(
  variables: GetBrandPageQueryVariables,
  options?: UseQueryOptions<GetBrandPageQuery, TError, TData>
) =>
  useQuery<GetBrandPageQuery, TError, TData>(
    ["GetBrandPage", variables],
    fetcher<GetBrandPageQuery, GetBrandPageQueryVariables>(GetBrandPageDocument, variables),
    options
  );

useGetBrandPageQuery.getKey = (variables: GetBrandPageQueryVariables) => [
  "GetBrandPage",
  variables,
];
useGetBrandPageQuery.fetcher = (
  variables: GetBrandPageQueryVariables,
  options?: RequestInit["headers"]
) =>
  fetcher<GetBrandPageQuery, GetBrandPageQueryVariables>(GetBrandPageDocument, variables, options);
export const GetCategoryPageDocument = `
    query GetCategoryPage($slug: String!) {
  CategoryPage(urlSlug: $slug) {
    categoryId
    categoryName
    urlSlug
    breadcrumb {
      categoryId
      categoryName
      urlSlug
    }
  }
}
    `;
export const useGetCategoryPageQuery = <TData = GetCategoryPageQuery, TError = SanitizedGQLError>(
  variables: GetCategoryPageQueryVariables,
  options?: UseQueryOptions<GetCategoryPageQuery, TError, TData>
) =>
  useQuery<GetCategoryPageQuery, TError, TData>(
    ["GetCategoryPage", variables],
    fetcher<GetCategoryPageQuery, GetCategoryPageQueryVariables>(
      GetCategoryPageDocument,
      variables
    ),
    options
  );

useGetCategoryPageQuery.getKey = (variables: GetCategoryPageQueryVariables) => [
  "GetCategoryPage",
  variables,
];
useGetCategoryPageQuery.fetcher = (
  variables: GetCategoryPageQueryVariables,
  options?: RequestInit["headers"]
) =>
  fetcher<GetCategoryPageQuery, GetCategoryPageQueryVariables>(
    GetCategoryPageDocument,
    variables,
    options
  );
export const GetCommunityCategoriesDocument = `
    query GetCommunityCategories($postcodes: [String!]!) {
  CommunityCategories(postcodes: $postcodes) {
    categoryName
    urlSlug
  }
}
    `;
export const useGetCommunityCategoriesQuery = <
  TData = GetCommunityCategoriesQuery,
  TError = SanitizedGQLError
>(
  variables: GetCommunityCategoriesQueryVariables,
  options?: UseQueryOptions<GetCommunityCategoriesQuery, TError, TData>
) =>
  useQuery<GetCommunityCategoriesQuery, TError, TData>(
    ["GetCommunityCategories", variables],
    fetcher<GetCommunityCategoriesQuery, GetCommunityCategoriesQueryVariables>(
      GetCommunityCategoriesDocument,
      variables
    ),
    options
  );

useGetCommunityCategoriesQuery.getKey = (variables: GetCommunityCategoriesQueryVariables) => [
  "GetCommunityCategories",
  variables,
];
useGetCommunityCategoriesQuery.fetcher = (
  variables: GetCommunityCategoriesQueryVariables,
  options?: RequestInit["headers"]
) =>
  fetcher<GetCommunityCategoriesQuery, GetCommunityCategoriesQueryVariables>(
    GetCommunityCategoriesDocument,
    variables,
    options
  );
export const GetStorePageDocument = `
    query GetStorePage($accountId: String!) {
  StorePage(accountId: $accountId) {
    accountId
    accountName
  }
}
    `;
export const useGetStorePageQuery = <TData = GetStorePageQuery, TError = SanitizedGQLError>(
  variables: GetStorePageQueryVariables,
  options?: UseQueryOptions<GetStorePageQuery, TError, TData>
) =>
  useQuery<GetStorePageQuery, TError, TData>(
    ["GetStorePage", variables],
    fetcher<GetStorePageQuery, GetStorePageQueryVariables>(GetStorePageDocument, variables),
    options
  );

useGetStorePageQuery.getKey = (variables: GetStorePageQueryVariables) => [
  "GetStorePage",
  variables,
];
useGetStorePageQuery.fetcher = (
  variables: GetStorePageQueryVariables,
  options?: RequestInit["headers"]
) =>
  fetcher<GetStorePageQuery, GetStorePageQueryVariables>(GetStorePageDocument, variables, options);
export const LocalityAutocompleteDocument = `
    query LocalityAutocomplete($localityPartial: String!) {
  LocalityAutocomplete(localityPartial: $localityPartial) {
    postcode
    locality
    state
    latitude
    longitude
  }
}
    `;
export const useLocalityAutocompleteQuery = <
  TData = LocalityAutocompleteQuery,
  TError = SanitizedGQLError
>(
  variables: LocalityAutocompleteQueryVariables,
  options?: UseQueryOptions<LocalityAutocompleteQuery, TError, TData>
) =>
  useQuery<LocalityAutocompleteQuery, TError, TData>(
    ["LocalityAutocomplete", variables],
    fetcher<LocalityAutocompleteQuery, LocalityAutocompleteQueryVariables>(
      LocalityAutocompleteDocument,
      variables
    ),
    options
  );

useLocalityAutocompleteQuery.getKey = (variables: LocalityAutocompleteQueryVariables) => [
  "LocalityAutocomplete",
  variables,
];
useLocalityAutocompleteQuery.fetcher = (
  variables: LocalityAutocompleteQueryVariables,
  options?: RequestInit["headers"]
) =>
  fetcher<LocalityAutocompleteQuery, LocalityAutocompleteQueryVariables>(
    LocalityAutocompleteDocument,
    variables,
    options
  );
export const VerifyEmailDocument = `
    mutation VerifyEmail($token: String!) {
  VerifyEmail(token: $token)
}
    `;
export const useVerifyEmailMutation = <TError = SanitizedGQLError, TContext = unknown>(
  options?: UseMutationOptions<VerifyEmailMutation, TError, VerifyEmailMutationVariables, TContext>
) =>
  useMutation<VerifyEmailMutation, TError, VerifyEmailMutationVariables, TContext>(
    ["VerifyEmail"],
    (variables?: VerifyEmailMutationVariables) =>
      fetcher<VerifyEmailMutation, VerifyEmailMutationVariables>(VerifyEmailDocument, variables)(),
    options
  );
useVerifyEmailMutation.getKey = () => ["VerifyEmail"];

useVerifyEmailMutation.fetcher = (
  variables: VerifyEmailMutationVariables,
  options?: RequestInit["headers"]
) =>
  fetcher<VerifyEmailMutation, VerifyEmailMutationVariables>(
    VerifyEmailDocument,
    variables,
    options
  );
export const UserProfileDocument = `
    query UserProfile($userId: String!) {
  user: User(userId: $userId) {
    accountId
    createDate
    avatarURL
    aboutMe
    legalName {
      firstName
      verified
    }
  }
}
    `;
export const useUserProfileQuery = <TData = UserProfileQuery, TError = SanitizedGQLError>(
  variables: UserProfileQueryVariables,
  options?: UseQueryOptions<UserProfileQuery, TError, TData>
) =>
  useQuery<UserProfileQuery, TError, TData>(
    ["UserProfile", variables],
    fetcher<UserProfileQuery, UserProfileQueryVariables>(UserProfileDocument, variables),
    options
  );

useUserProfileQuery.getKey = (variables: UserProfileQueryVariables) => ["UserProfile", variables];
useUserProfileQuery.fetcher = (
  variables: UserProfileQueryVariables,
  options?: RequestInit["headers"]
) => fetcher<UserProfileQuery, UserProfileQueryVariables>(UserProfileDocument, variables, options);
export const ChatChannelDocument = `
    query ChatChannel($channelId: String!) {
  ChatChannel(channelId: $channelId) {
    __typename
    ... on ChatChannel {
      ctaText
      isOwner
      isSeeker
      listingId
      listing {
        listingId
        title
        user {
          legalName {
            firstName
          }
          avatarURL
        }
        images {
          thumbnailURL
        }
      }
      seekerUser {
        _id
        createDate
        avatarURL
        legalName {
          firstName
          lastName
          verified
        }
      }
      bookingId
      booking {
        dateStart
        dateEnd
      }
    }
    ... on GenericError {
      errorCode
      errorMessage
    }
  }
}
    `;
export const useChatChannelQuery = <TData = ChatChannelQuery, TError = SanitizedGQLError>(
  variables: ChatChannelQueryVariables,
  options?: UseQueryOptions<ChatChannelQuery, TError, TData>
) =>
  useQuery<ChatChannelQuery, TError, TData>(
    ["ChatChannel", variables],
    fetcher<ChatChannelQuery, ChatChannelQueryVariables>(ChatChannelDocument, variables),
    options
  );

useChatChannelQuery.getKey = (variables: ChatChannelQueryVariables) => ["ChatChannel", variables];
useChatChannelQuery.fetcher = (
  variables: ChatChannelQueryVariables,
  options?: RequestInit["headers"]
) => fetcher<ChatChannelQuery, ChatChannelQueryVariables>(ChatChannelDocument, variables, options);
export const CreateChannelDocument = `
    mutation CreateChannel($listingId: String!) {
  CreateChannel(listingId: $listingId) {
    __typename
    ... on ChatChannelCreateSuccess {
      channelId
    }
    ... on GenericError {
      errorCode
      errorMessage
    }
  }
}
    `;
export const useCreateChannelMutation = <TError = SanitizedGQLError, TContext = unknown>(
  options?: UseMutationOptions<
    CreateChannelMutation,
    TError,
    CreateChannelMutationVariables,
    TContext
  >
) =>
  useMutation<CreateChannelMutation, TError, CreateChannelMutationVariables, TContext>(
    ["CreateChannel"],
    (variables?: CreateChannelMutationVariables) =>
      fetcher<CreateChannelMutation, CreateChannelMutationVariables>(
        CreateChannelDocument,
        variables
      )(),
    options
  );
useCreateChannelMutation.getKey = () => ["CreateChannel"];

useCreateChannelMutation.fetcher = (
  variables: CreateChannelMutationVariables,
  options?: RequestInit["headers"]
) =>
  fetcher<CreateChannelMutation, CreateChannelMutationVariables>(
    CreateChannelDocument,
    variables,
    options
  );
export const ChatTokenDocument = `
    query ChatToken {
  ChatToken {
    __typename
    ... on ChatToken {
      token
    }
    ... on GenericError {
      errorCode
      errorMessage
    }
  }
}
    `;
export const useChatTokenQuery = <TData = ChatTokenQuery, TError = SanitizedGQLError>(
  variables?: ChatTokenQueryVariables,
  options?: UseQueryOptions<ChatTokenQuery, TError, TData>
) =>
  useQuery<ChatTokenQuery, TError, TData>(
    variables === undefined ? ["ChatToken"] : ["ChatToken", variables],
    fetcher<ChatTokenQuery, ChatTokenQueryVariables>(ChatTokenDocument, variables),
    options
  );

useChatTokenQuery.getKey = (variables?: ChatTokenQueryVariables) =>
  variables === undefined ? ["ChatToken"] : ["ChatToken", variables];
useChatTokenQuery.fetcher = (
  variables?: ChatTokenQueryVariables,
  options?: RequestInit["headers"]
) => fetcher<ChatTokenQuery, ChatTokenQueryVariables>(ChatTokenDocument, variables, options);
export const GetCreditDocument = `
    query GetCredit {
  GetCredit {
    creditBalance
    creditHistory {
      ...CreditHistoryTable_CreditHistory
    }
  }
}
    ${CreditHistoryTable_CreditHistoryFragmentDoc}`;
export const useGetCreditQuery = <TData = GetCreditQuery, TError = SanitizedGQLError>(
  variables?: GetCreditQueryVariables,
  options?: UseQueryOptions<GetCreditQuery, TError, TData>
) =>
  useQuery<GetCreditQuery, TError, TData>(
    variables === undefined ? ["GetCredit"] : ["GetCredit", variables],
    fetcher<GetCreditQuery, GetCreditQueryVariables>(GetCreditDocument, variables),
    options
  );

useGetCreditQuery.getKey = (variables?: GetCreditQueryVariables) =>
  variables === undefined ? ["GetCredit"] : ["GetCredit", variables];
useGetCreditQuery.fetcher = (
  variables?: GetCreditQueryVariables,
  options?: RequestInit["headers"]
) => fetcher<GetCreditQuery, GetCreditQueryVariables>(GetCreditDocument, variables, options);
export const GetReferrerDocument = `
    query GetReferrer($referrerId: String!) {
  Referrer: User(userId: $referrerId) {
    _id
  }
}
    `;
export const useGetReferrerQuery = <TData = GetReferrerQuery, TError = SanitizedGQLError>(
  variables: GetReferrerQueryVariables,
  options?: UseQueryOptions<GetReferrerQuery, TError, TData>
) =>
  useQuery<GetReferrerQuery, TError, TData>(
    ["GetReferrer", variables],
    fetcher<GetReferrerQuery, GetReferrerQueryVariables>(GetReferrerDocument, variables),
    options
  );

useGetReferrerQuery.getKey = (variables: GetReferrerQueryVariables) => ["GetReferrer", variables];
useGetReferrerQuery.fetcher = (
  variables: GetReferrerQueryVariables,
  options?: RequestInit["headers"]
) => fetcher<GetReferrerQuery, GetReferrerQueryVariables>(GetReferrerDocument, variables, options);
